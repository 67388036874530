import React, { useEffect } from 'react';
// import InnerBanner from '../component/InnerBanner/InnerBanner';
// import * as Style from './Highrox.style';
import { Box, Container, Stack, VStack, SimpleGrid, GridItem, Flex, Image, Accordion, AccordionIcon, AccordionItem, AccordionPanel, AccordionButton, Link } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
// import VideoCard from '../component/VideoCard/VideoCard';
import ClassCard from '../component/Classcard/ClassCard';
import ContactForm from '../component/Form/ContactForm';
import LinkButton from '../component/Button/LinkButton';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import PriceBox from '../component/Price Component/PriceBox';
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';

import BlogSection from '../component/Blog/BlogSection';



const metaTitle = "HYROX Training Dublin | Perpetua Fitness"
const metaDesc = "The world series of fitness racing. HYROX training in Dublin with Perpetua Fitness. HYROX is a global fitness race for Every Body."



function Highrox(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* <InnerBanner
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/hyrox_m.jpg)', md:'url(./images/Hyrox.webp)'}} 
        title='Hyrox Classes'
        subtitle='THE WORLD SERIES OF FITNESS RACING'
        issubtextpara={false} 
        buttontitle='BOOK A CLASS'
        issubtitleRight={true} 
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>}  
        width={{sm:'100%',md:'100%' }}
        height={{ sm:'450px', md:'500px', lg:'550px', xl:'767px'}}
        margin="30px 0 0"
        Link="/pricing"
        ButtonModalmarginTop={{sm:'10px', md:'30px'}}
        videoModal='https://www.youtube.com/embed/IOu_ndmzDKE'    
      ></InnerBanner> */}

      <VideoBanner
        videobanner='../video/Hyrox-banner.mp4'
        title="HYROX DUBLIN"
        subtitle="THE WORLD SERIES OF FITNESS RACING"
        isButton={true}
        issubtextpara={false}
        buttontitle="Book a class"
        margin="0px 0 0"
        Link="/timetable"
        videoModal='https://www.youtube.com/embed/IOu_ndmzDKE'
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane Facility</Box>}
        issubtitleRight={true}
      />

      <Flex backgroundColor='black' padding="80px 0px">
        <Container maxW='container.xl'>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <PriceBox
                color='white'
                currency='€'
                priceValue='179'
                time='/per month'
                description='Arrange a call with our Head of Hyrox to get our induction to our classes and facilities'
                buttonText='Book a class'
                link='/timetable'
                buttonPading='0px'
                issubHeading={false}
                width={{ base: '100%', sm: '70%' }}
              >
              </PriceBox>
            </Box>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='WHATS INCLUDED ' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <SimpleGrid columns={{ base: '1', lg: '2' }} spacing='0px' className='_whatsinclude_box_body'>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/weight-icon.svg' 
                    title='Unlimited Hyrox Classes'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Hyrox Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/gym.svg' 
                    title='Access to JustGYM Area'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Hyrox Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/madel.svg' 
                    title='Online Program'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Hyrox Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    //Imguri='/images/open-gym.svg' 
                    title='Hyrox Workshops'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="Hyrox Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <Flex backgroundColor='black' padding="80px 0px" borderTop='1px solid #232323' borderBottom='1px solid #232323'>
        <Container maxW='container.xl'>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <PriceBox
                color='white'
                currency={false}
                priceValue='PAY AS YOU GO'
                time={false}
                description='PURCHASE CLASS CREDITS AND USE THEM TO BOOK INTO HYROX SESSIONS'
                buttonText='Book a class'
                link='/timetable'
                buttonPading='0px'
                issubHeading={false}
                isButton={true}
                width={{ base: '100%', sm: '70%' }}
              >
              </PriceBox>              
            </Box>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='Additonal Info ' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <Text padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} color='#fff' title='ANY STUDIO CREDIT CAN BE USED FOR HYROX AT PERPETUA! ALL STUDIO CREDIT TERMS AND CONDITIONS APPLY INCLUDING OUR 8-HOUR CANCELLATION POLICY' width='100%' ></Text>
            </Box>
          </Stack>
        </Container>
      </Flex>

      {/* <Container maxW='container.xl' padding={{sm:'0px 15px', md:'0px 30px',lg:'0px'}} background="#fff">
            <Style.HighroxInfo>
                <VideoCard src='https://www.youtube.com/embed/HTHAs8F9Cek' width={{sm:'100%', md:'100%',lg:'40%'}} margin={{md:'13px 0px 0px', lg:'19px 0px 0px'}} maxHeight='340px'></VideoCard>
                <VideoCard src='https://youtube.com/embed/kjuQKgCC1ZE?si=QL6J_tCUdnR7NdMM' width={{sm:'100%', md:'100%',lg:'40%'}} margin={{md:'13px 0px 0px', lg:'19px 0px 0px'}} maxHeight='340px'></VideoCard>
                <Style.HighroxContent>
                        <Box paddingTop='30px'>
                          <Heading title='WHAT HAPPENS IN A HYROX CLASS?' variant='large'></Heading>
                          <Text title='In our Hyrox class our coaching team is going to focus on strength, speed and running efficiency. If
you’ve competed or looking to compete in a Hyrox event and want to increase your performance in
all areas, then this is the class for you. '></Text>
<Text title='We have developed a program which is structured to get you the results you want. Along with
building your running endurance, we will also focus on machine efficiency along with strength work.
We will get you the results you want along with enjoying the process.'></Text>
                        </Box>
                </Style.HighroxContent>
            </Style.HighroxInfo>
        </Container> */}

      {/* <Style.SportsFitness background='black'>
          <Container maxW='container.xl'>
            <Heading title='What is Hyrox?' variant='large' margin='0px 0px 20px' color='white'></Heading>
            <Text color='white' width={{sm:'100%',md:'95%',lg:'80%'}} margin='0px auto' title='HYROX is a global fitness race for Every Body. Participants from all around the world compete in the exact same format. The race starts with a 1 km run, followed by 1 functional movement, which repeats 8 times, taking place in large indoor venues, creating a unique stadium like atmosphere for all participants'></Text>
          </Container>
        </Style.SportsFitness> */}

      {/* <Container maxW='container.xl' padding={{ sm:'50px 20px',md:'40px 50px' ,lg:'40px 50px 40px' ,xl:'80px 0px 80px'}} >
        <Stack direction={{sm:'column',md:'row',lg:'row'}} spacing='30px'>
          <Box w={{sm:'100%',md:'50%'}} h={{sm:'auto',md:'40px'}} position={{sm:'relative',md:'sticky'}} top={{sm:'inherit',md:'100px'}} >
            <Heading title='Mass Participation' variant='extralarge' lineHeight='1' padding={{sm:'0px 0px 15px',md:'0px'}}></Heading>
            <Text title='Hyrox is truly accessible: with 4 different racing categories to choose from, HYROX is for everyone no matter the fitness level:'></Text>
          </Box>
          <Box w={{sm:'100%',md:'50%'}}>
              <VStack spacing={2}align='stretch' width={"full"}>
                <Stack p={{sm:'0px 0px 20px',md:'0px 0px 50'}} borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='01' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='Open ' variant='medium' margin='0px 0px 30px' width='50%' lineHeight='1'></Heading>
                  <Text as='p' title='Take on the standard HYROX for a challenging but achievable race for everyone.'></Text>
                </Stack>
                <Stack p={{sm:'20px 0px',md:'50px 0px'}} borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='02' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='Pro ' variant='medium' margin='0px 0px 30px' width='50%' lineHeight='1'></Heading>
                  <Text as='p' title='For the experienced racer, heavier weights make for a more challenging experience.'></Text>
                </Stack>
                <Stack p={{sm:'20px 0px',md:'50px 0px'}} borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='03' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='Doubles' variant='medium' margin='0px 0px 30px' width='50%' lineHeight='1'></Heading>
                  <Text as='p' title='Find a partner and take on the challenge as a pair, running together but splitting the workload of the exercises.'></Text>
                </Stack>
                <Stack p={{sm:'20px 0px',md:'50px 0px'}}>
                  <Heading as='h2' title='04' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='Relay' variant='medium' margin='0px 0px 30px' width='50%' lineHeight='1'></Heading>
                  <Text as='p' title='Come together with your friends or family and race the relay where each member of the team does 2 times 1 km and 2 workouts.'></Text>
                </Stack>
              </VStack>
          </Box>
        </Stack>
      </Container> */}

      {/* <Flex flexDirection='column' padding={{sm:'50px 0px', md:'80px 0px',lg:'100px 0px 130px' }} borderTop='1px solid' borderTopColor='LightGrey'>
        <Container maxW='container.xl'>
          <Heading 
            as="h2" 
            title="HOW TO JOIN A HYROX CLASS"
            variant='extralarge'
            textAlign="center"
            lineHeight="1"
            width={{lg:'100%', xl:'100%'}}
            padding={{sm:'0px 0px 30px', md:'0px 0px 50px',lg:'0px 0px 80px' }}
          >
          </Heading>

          <SimpleGrid 
            columns={{sm:'1',md:'2'}} 
            columnGap={10} 
            rowGap={2} 
            width="full" 
          >
              <GridItem>
                <ClassCard
                    title='PAY AS YOU GO'
                    subtitle='Purchase class credits and use them to book into HYROX Sessions. Any studio credit can be used for Hyrox at Perpetua! All studio credit terms and conditions apply including our 8-hour cancellation policy.                    '
                    bg="url('/images/Hyroxpay_as_you_go.jpg')"
                    link="/studios"
                    marginBottom='30px'
                    transform="uppercase"
                    minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                    buttonTitle='PURCHASE CREDITS'
                />
              </GridItem>
              <GridItem mt={{base:'20px', sm:'40px', xl:'84px'}}>
                <ClassCard
                    title='HYROX MEMBERSHIP'
                    subtitle='€179/month; 3 month commitment. This membership gives you unlimited HYROX classes as well as a free subscription to Perpetua Online Programming! After your 3 months, your membership will roll month to month and you can cancel at any time by giving us 30-days notice.'
                    bg="url('/images/mambership.jpg')"
                    link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodid=158"
                    marginBottom='30px'
                    transform="uppercase"
                    minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                    buttonTitle='PURCHASE MEMBERSHIP'
                    targetBlank={true}
                />
              </GridItem>
          </SimpleGrid>

        </Container>
      </Flex> */}

      <Flex flexDirection='column' padding={{ sm: '0', md: '50px 0px 60px', lg: '70px 20px 70px', xl: '80px 0px 80px' }} background='#000' borderBottom='1px solid #232323'>
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="What happens in a Hyrox class"
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            padding={{ sm: '40px 0px', md: '0px 0px 70px' }}
            className='wow animate fadeInUp'
            color='#fff'
          >
          </Heading>
        </Container>
        <Container maxW='container-fluid'>
          <SimpleGrid
            columns={{ sm: '1', md: '2', lg: '2', xl: '3', xxl: '4' }}
            columnGap={4}
            rowGap={2}
            width="full"
            marginTop='50px'
            padding={{ sm: '0px', md: '0px 30px' }}
          >
            <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
              <ClassCard
                title='WARM UP'
                subtitle='Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                  you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                  up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!'
                bg="url('/images/Hyrox/WarmUp.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '40px', xl: '80px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='Conditioning'
                subtitle='Each session we will focus on your conditioning, so you are prepared for a Hyrox event.
                  We will focus on running, rowing and ski erg technique as well as additional movements which are
                  you are expected to complete. Get ready to work and enjoy getting prepared for an event.'
                bg="url('/images/Hyrox/Conditioning.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', lg: '0', xl: '160px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='Strength WORK'
                subtitle='To finish each session, we will do additional strength work. This is a big part of improving
                  your event times so we will look to build on leg and pulling strength.'
                bg="url('/images/Hyrox/Strength.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', md: '40px', lg: '40px', xl: '0', xxl: '240px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='COOL DOWN & HIGH FIVES'
                subtitle='We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                  to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                  Mojo Health Bar'
                bg="url('/images/Hyrox/Cooldown.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>


      <VideoSection heading='Get Started with' headingSub='Hyrox Classes' video1='https://youtube.com/embed/kjuQKgCC1ZE?si=HBeyrouzPC_7SSeJ' video2='https://youtube.com/embed/OsqLDOMAzAE?si=wDXy6MxEQyXg0YmI' smallHeading='Check out a class' smallHeading2='Whats Included' midHeading='' midHeading2='' />
      {/* Faq */}

      <BlogSection catSlug='hyrox' />
      <Flex p={{ sm: '0px 0px 50px', md: '20px 0px 90px' }} marginTop='50px'>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }} /*position={{ base: 'relative', md: 'sticky' }} top={{ sm: '0', md: '100px' }} height={{sm:'auto',md:'150px' }} textAlign={{ sm: 'center', md: 'left' }} */>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='What is hyrox?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' HYROX is a global fitness race for Every Body. Participants from all around the world compete in the exact same format. The race starts with a 1 km run, followed by 1 functional movement, which repeats 8 times, taking place in large indoor venues, creating a unique stadium like atmosphere for all participants.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title=' How do I join a class?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="You can choose between buying a studio credit to join the class or you can start a hyrox membership which gives you access to all Hyrox classes and online programming. You can book a class through the Perpetua Fitness app." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="How many Hyrox classes are available?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='We have 6 Hyrox classes you can choose from. Monday 6.45pm, Tuesday 8.15am, Wednesday
6.45pm, Thursday 8.15am, Saturday 8am and Sunday 10am. Classes range from 45-60mins.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are your hours of operation?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Windmill Lane Studio: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm.' margin='0px 0px 20px'></Text>
                    <Text title='Lennox Street Studio: Monday-Friday 6am-12pm, 4pm-8pm | Saturday-Sunday 9am-12pm .' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have a place I can securely leave my stuff while I work out?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have showers?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=" Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)" margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <VStack backgroundColor='black' padding='96px 0px' borderBottom='1px solid #232323'>
        <Container
          display='flex'
          maxW='container.xl'
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{ base: '100%', lg: '50%' }} textAlign={{ base: 'center', lg: 'left' }} marginBottom={{ base: '50px', lg: '0' }}>
            <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
            <Heading title='Chat with a member of the team' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
            <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{ textDecoration: 'none' }}>
              <LinkButton
                title='BOOK A CALL'
                Imguri='/images/red_arrow.svg'
                border='2px solid' borderColor='Red'
                padding='20px 25px'
                color='White'
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ base: '100%', lg: '50%' }}>
            <ContactForm pageName="Hyrox"></ContactForm>
          </Box>
        </Container>
      </VStack>


      {/*  */}
      {/* <Flex borderTop='1px solid' borderTopColor='LightGrey'>
        <Container maxW={{ sm:'Container.sm', md:'container.md',lg:'container.lg',xl:'container.xl'}} padding={{md:'50px 0px',lg:'100px 0px'}}>
            <Helmet>
                <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js " type="text/javascript"></script>
            </Helmet>
            <healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="70826290014" data-widget-version="1"></healcode-widget>
        </Container>
      </Flex> */}

    </>
  );
}

export default Highrox;