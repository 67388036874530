import React from 'react';
import {Box,Text, Link} from '@chakra-ui/react';
// import LinkButton from '../Button/LinkButton';
/* import{Link} from 'react-router-dom'; */
import { defaultClasscardV2Props } from './ClasscardV2.type';
import Heading from '../Heading/Heading';
import * as Style from './ClasscardV2.style';
import MyImage from '../LazyLoadImage/MyImage';

function ClasscardV2(props) {
  return (
    <Link 
      margin={props.margin}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      minHeight={props.minHeight} 
      position="relative"
      className="wow fadeInUp" 
      href={props.link} 
      isExternal={props.targetBlank}
      width={props.width}
    >
      {props.isHeading || props.isSubheading ? (   
        <Style.ImageBox >
          <Style.ImageContainer>
                {/* <Style.ImagePlace src={props.Image} alt={props.alt}/> */}
                <MyImage alt={props.alt} src={props.imageUri} width={props.width} height={props.height} objectFit='props.objectFit' objectPosition={props.objectPosition} />
                <Style.ImageHeading>
                    {props.isHeading &&
                        <Heading p="0" m="0" lineHeight={"1"} as="h3" color='white' variant="medium" title= {props.title} display='inline-block'></Heading>
                    }
                </Style.ImageHeading>
          </Style.ImageContainer>  
          <Box textAlign='center'>
            {props.isSubheading &&
              <Text as="p" color='#fff' fontFamily={'poppins'} fontSize='xs' fontWeight='500' textTransform={props.transform} >{props.subtitle}</Text>
            }
          </Box>
        </Style.ImageBox>

      ): null
      }
        
    </Link>
  )
}

export default ClasscardV2;
ClasscardV2.defaultProps = defaultClasscardV2Props