import React from 'react';
import { Link as LinkChakra } from '@chakra-ui/react'


const Link = (props) => {
    return (
        <>
            <LinkChakra 
                color={props.color} 
                fontSize={props.fontSize} 
                lineHeight={props.lineHeight} 
                textTransform={props.Transform} 
                p={props.padding} 
                m={props.margin} 
                href={props.href}
                isExternal={props.targetBlank}
                fontFamily={props.fontFamily}
                textDecoration={props.textDecoration}

                _hover={
                    {textDecoration:'none',color:'Red'}
                }
                
                >
                    {props.title}
            </LinkChakra>
        </>
    );
}

export default Link;
// Heading.defaultProps = defaultHeadingProps