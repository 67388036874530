import React from 'react';
import { Container , Box} from '@chakra-ui/react'
import * as Style from '../component/Style/TermsConditions.style';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import MetaTitle from './MetaTitle';
function TermsConditions(){
    return(
        <>
             <MetaTitle title='Terms & Condition | Perpetua Fitness'/>
            <Box padding={{sm:'30px 0 40px', md:'10px 0 50px'}}>
            <Container maxW='container.xl'>

                <Style.TermsConditionHeading>
                    <Heading title='Terms and Conditions:' variant='smallmid' padding='10px 0px' />
                    <Text title='Last Updated: 12/05/23 ' padding='10px 0px' />
                    <Text title="The Services are operated by CrossFit Ireland LTD (“us“, “we“, “our” or “Perpetua Fitness“). 
                    By accessing, browsing, and/or using the Site and/or any facility operated by Perpetua Fitness (a “Club”), 
                    you accept and agree to be bound to these Terms. In addition, when using particular Perpetua Fitness services, 
                    you also shall be subject to any posted guidelines, FAQs, or rules applicable to such services, which may be 
                    posted and modified from time to time. You agree that you are at least 16 years old and that if you are between 
                    16 years and 18 years old, your legal guardian has reviewed and agrees to these terms and is happy for you to access 
                    and/or use our Services. We reserve the right, from time to time, with or without notice, to change these Terms at our 
                    sole discretion, and the latest version will appear on our website (perpetua.ie) with the date that it was last updated. 
                    By using the Service after any changes have been posted, you agree to the new terms.  " padding='10px 0px 30px'>

                    </Text>
                    
                </Style.TermsConditionHeading>
                <Style.TermsUnorderedList> 
                    <Heading title='The Use of Perpetua Services: ' variant='smallmid'/>
                    <Style.TermsListItems>
                        In order to use the services provided in Perpetua you will need to create a Perpetua Fitness account with us 
                        and purchase a pricing option, including but not limited to membership contracts and studio credit bundles.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        You must be at least 16 years old in order to attend any of our classes or use of facilities. Anyone between 
                        the age of 16-18 will need parental consent. 
                    </Style.TermsListItems>
                  
                </Style.TermsUnorderedList> 
                <Style.TermsUnorderedList>
                 
                    <Heading title='Your Use of Our Services: ' variant='smallmid'  />
                    <Style.TermsListItems>
                        You agree to comply with our Class rules which you can see online or displayed in all our locations. 
                        The access rules relate to our opening hours, use of our facilities and your conduct.
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        You should not exercise beyond your own abilities. If you know or are concerned that you may have a 
                        medical condition which might interfere with you exercising safely, before you use our equipment and 
                        facilities you should get advice from a relevant medical professional and follow that advice.
                     </Style.TermsListItems>
                    <Style.TermsListItems>
                        You should make yourself aware of any rules and instructions, including warning notices, and follow them. 
                        Exercise carries its own risks. You should not carry out any activities which you have been told are not suitable for you.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Whilst using the facilities or equipment, you should let us know immediately if you feel unwell. Our staff members are not 
                        qualified doctors, but we have first aid available within the facility. If you have a disability, you must follow any reasonable 
                        instructions to allow you to exercise safely.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        By attending classes, events and activities, and using the studio facilities and equipment, you hereby acknowledge on behalf of yourself, 
                        your heirs, personal representatives and/or assigns, that there are a certain inherent risks and dangers in the strenuous nature of exercise. 
                        You acknowledge that you have voluntarily chosen to participate in intense physical exercise. You hereby agree to assume full responsibility 
                        for all injuries or damage, which are sustained or aggravated by you in relation the use of equipment and/or studio facilities, and release, 
                        indemnify, and hold harmless Perpetua Fitness.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        We will not be liable to you in respect of any of the following: 
                        <Style.TermsSubText >
                            <Text title='i.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Your personal property that is lost, stolen or damaged before, during or after class. Please ensure you keep all valuables in the lockers provided. ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='ii.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Personal injury (including without limitation, serious injury or death) that you may suffer or sustain directly or indirectly as a result of attending our classes.  ">
                            </Text>
                        </Style.TermsSubText>
                    </Style.TermsListItems>                  
                </Style.TermsUnorderedList>

                <Style.TermsUnorderedList> 
                    <Heading title='Studio Credits: '  variant='smallmid' />
                    <Style.TermsListItems>
                        To book a Class, your account must include at least one Credit. 
                        One Credit entitles you to attend one Class. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        You can purchase Credits via our Website, or the app. Multiple credits can be 
                        purchased as bundles at discounted rates.
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Perpetua Fitness credit holders receive the number of classes commensurate with the 
                        package purchased. Such classes may be redeemed at any studio operated by Perpetua until 
                        such time that the credits are exhausted whether through consumption or expiry.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Credit holders may not transfer credits to third parties, including to other Perpetua credit 
                        holders. In addition to this, you should not allow anyone else to book into class using your 
                        credits / name. Perpetua makes no guarantee on the availability of classes as access to classes 
                        is on a space-available basis.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Subject to any statutory right of cancellation, payments for credits are non-refundable nor 
                        transferable to other members unless otherwise stated in these Conditions. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Your credit activates on the date that you attend the first class (excluding promotional credits 
                        which activate on date of purchase). 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Class packs all have strict expiry dates and are as follows: 
                        <Style.TermsSubText >
                            <Text title={<Box>*3 for 33 Introductory Offer = 10 days <br/>
                                *Kickstarter Pack 6 Credits = 1 month <br/> 
                                1 Credit = 1 month <br/>
                                3 Credit = 30 days<br/>
                                5 Credits = 45 days<br/>
                                10 Credits = 3 months<br/>
                                15 Credits = 6 months<br/>
                                20 Credits = 9 months<br/></Box>} />
                        </Style.TermsSubText>

                        <Text title='Both the 3 for 33 and Kickstarter Pack are 
                                introductory offers and can only be redeemed once per person.' padding='10px 0' />
                    </Style.TermsListItems>
                </Style.TermsUnorderedList>

                <Style.TermsUnorderedList>
                    <Heading title='Studio Memberships: '  variant='smallmid' />
                    
                    <Text title="Your Studio Membership (Studio8, Studio12, Studio16) starts on the date that you 
                        sign up and submit payment via a valid payment method. Each membership starts with a minimum 
                        3-month contract during which it cannot be paused or cancelled, afterwards it will automatically 
                        roll monthly. For example, if you purchase your membership on the 5th of April, your membership 
                        will automatically renew on 5th May. Once your new membership cycle starts, you will have access 
                        to the eligible classes for the new studio membership cycle. You must provide us with a current, 
                        valid, accepted method of payment (as such may be updated from time to time, “Payment Method”) to 
                        use Perpetua Fitness. We will automatically bill the monthly fee to your Payment Method each cycle 
                        until your membership is cancelled or terminated. A studio membership is subject to your membership 
                        being cancelled (by you) or terminated (by us). Credits cannot be extended or transferred across months."
                    padding='10px 0 0 15px'/>

                    <Text title="In order to cancel a studio membership, you must provide a minimum of 30 days' written notice. 
                    If your next scheduled payment is due in less than 30 days when the notice is given, that payment will still 
                    be processed, and your cancellation will activate as of the following month." padding='10px 0 10px 15px' />
                </Style.TermsUnorderedList>
                {/*  */}
                <Style.TermsUnorderedList>
                    <Heading title='Refunds: '  variant='smallmid'   />
                    <Style.TermsListItems>
                        Unless expressly stated to the contrary herein, or as provided by applicable law, payments are nonrefundable and there 
                        are no refunds or credits for partially used periods.
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        By law, you have the right to a ‘Cooldown Period’ of 7 days after the date of purchase of a contract. However, 
                        once the contract has been utilised, i.e. a class booked, you forfeit the right to enforce a ‘Cooldown Period’ termination.  
                    </Style.TermsListItems>
                   
                </Style.TermsUnorderedList>
                <Style.TermsUnorderedList>
                    <Heading title='Payment Methods: '  variant='smallmid' />
                    <Style.TermsListItems>
                        You may edit your Payment Method information by logging into your desktop Perpetua account and updating accordingly. 
                        If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not edit your 
                        payment information, provided that you have not cancelled your account in accordance with these Terms (see, “Cancellation”), 
                        you remain responsible for any uncollected amounts. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Memberships that have a 3-month minimum term can't be cancelled until after your first 3 payments. You may terminate your 
                        membership at any time after this point with at least 30 days prior notice before your next renewal date. For example, if your 
                        next renewal date is 30th September, you need to cancel before 1st September in order to avoid being charged for the next cycle. 
                        Note that if you do terminate your membership, you will be subject to current membership rates and the initial contract period 
                        again upon your return. 
                    </Style.TermsListItems>                   
                </Style.TermsUnorderedList>

                <Style.TermsUnorderedList>
                    <Heading title='Other Fees: '  variant='smallmid'   />
                    <Style.TermsListItems>
                        If you have an unlimited membership and you book into a class and either Late Cancel or No Show, you are subject to a €5 fee 
                        for every instance. This will be charged at the start of the following week. It is your responsibility to check in at Front 
                        of House to avoid this fee being charged.
                    </Style.TermsListItems>                 
                </Style.TermsUnorderedList>

                <Style.TermsUnorderedList>
                    <Heading title='General Policies: '  variant='smallmid' />
                    <Style.TermsListItems>
                        Studio Credits and/or Credit Bundles only give access to the class for which you signed up (at the specified time and location). 
                        Please note that we operate a strict closed-door policy. For health and safety reasons, once the session has begun and the coach 
                        has closed the door, we are unable to allow entry or return credits to anyone that has arrived late. As this is in the best interest 
                        of both your experience and our team, we ask that this is respected by all guests. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        To ensure that all guests have the opportunity to attend classes, we have an 12 hours cancellation window. This means that provided you 
                        cancel your booking with at least 12 hours' notice, you get to keep your class credit and use it another day. If you cancel within 12 hours 
                        of the class start time, it is considered a late cancel and results in the loss of that class credit. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        If it is your first time at a studio or to a concept, we kindly ask that you get here at least 5 minutes before class starts to get the 
                        mandatory induction. Unfortunately, new guests arriving within 5 minutes of class start time will not be admitted and the credit will not be returned.  
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        For health and safety reasons, we enforce a strict closed-door policy. Once the session has begun and the coach has closed the door, we are unable 
                        to allow entry or return credits to anyone that has arrived late. As this is in the best interest of both your experience and our team, we ask that 
                        this is respected by all guests. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        If you would like to book into a class but it is full, you will be given the opportunity to join the ‘Waitlist’. Please note that by joining a 
                        waitlist you are committing to a spot should a space become available.
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Please note, both wraps and gloves are mandatory when taking part in Rumble. Gloves are provided and wraps can be bought at Front of House for €10.
                    </Style.TermsListItems>
                </Style.TermsUnorderedList>

                <Style.TermsUnorderedList>
                    <Heading title='Privacy: '  variant='smallmid' />
                    <Style.TermsListItems>
                        Your privacy is important to us. The Perpetua Fitness Privacy Policy is hereby incorporated into these Terms by reference. Please 
                        read the privacy policy carefully for information relating to Perpetua’s collection, use, and disclosure of your personal information. 
                    </Style.TermsListItems>
                </Style.TermsUnorderedList>
                <Style.TermsUnorderedList>
                    <Heading title='Prohibited Site Conduct: '  variant='smallmid'/>
                    <Style.TermsListItems>
                        By using the Site, you promise to:
                        <Style.TermsSubText >
                            <Text title={<Box>Respect our facility.  <br/>
                                Respect our procedures.  <br/> 
                                Respect our teams. <br/>
                                Respect our customers. <br/>
                                Respect our product. <br/>
                                </Box>} />
                        </Style.TermsSubText>
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        By using the Site, you promise not to: 
                        <Style.TermsSubText >
                            <Text title='i.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Harass, threaten, or defraud Site users;  ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='ii.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Make unsolicited offers, advertisements, proposals, or send junk mail to users;   ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='iii.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Impersonate another person or access another user’s account without that person’s permission;   ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='iv.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Share issued passwords with any third party or encourage any other user to do so;  ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='v.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Permit third parties to use any classes booked under your credits, including other credit  holders;   ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='vi.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Misrepresent the source, identity, or content of information transmitted via the Site, including deleting the 
                                copyright or other proprietary rights; ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='vii.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Upload material (e.g., virus) that is damaging to computer systems or data of Perpetua Fitness or users of the Site;  ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='viii.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Upload copyrighted material that is not your own or that you do not have the legal right to distribute, 
                                display, and otherwise make available to others; or  ">
                            </Text>
                        </Style.TermsSubText>
                        <Style.TermsSubText >
                            <Text title='ix.'padding='0px 10px' ></Text>
                            <Text 
                                title ="Upload or send to Site users pornographic, threatening, embarrassing, hateful, racially or ethnically insulting, 
                                libellous, or otherwise inappropriate content.  ">
                            </Text>
                        </Style.TermsSubText>
                    </Style.TermsListItems>
                    
                </Style.TermsUnorderedList>
                <Style.TermsUnorderedList>
                    <Heading title='Disclaimers:'  variant='smallmid' />
                    <Style.TermsListItems>
                        You agree that attendance at or use of any such classes, activities, or other services is solely at your own risk. In no event shall Crossfit 
                        Ireland LTD be liable for any injury, loss, claim, damage or any special, exemplary, punitive, incidental or consequential damages of any kind, 
                        whether based in contract, tort or otherwise, which arises out of or is any way connected with a user’s attendance or participation in a class, 
                        service or appointment made through the site, or the performance or non-performance in connection with the services. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                        Unless otherwise expressly stated by Perpetua, the site and any downloadable software, content, services, or applications made available in 
                        conjunction with or through the site are provided “as is” and “as available” without warranties of any kind either express or implied. To the 
                        fullest extent permissible pursuant to applicable law, Perpetua, its suppliers and partners disclaim all warranties, statutory, express or implied, 
                        including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights. 
                    </Style.TermsListItems>
                </Style.TermsUnorderedList>

                <Style.TermsUnorderedList>
                    <Heading title='Termination by CrossFit Ireland LTD: ' variant='smallmid'  />
                    <Style.TermsListItems>
                        You agree that Perpetua Fitness, in its sole discretion and for any or no reason, may terminate any account (or any part hereof) you may have with 
                        Perpetua or use of the Site and/or Club and remove and discard all or any part of your account or any content uploaded by you, at any time. 
                    </Style.TermsListItems>
                    <Style.TermsListItems>
                    Perpetua may also in its sole discretion and at any time discontinue providing access to the Site and/or Club, or any part thereof, with or without notice. 
                    You agree that any termination of your access to the Site or any account you may have or portion thereof and/or Club may be affected without prior notice, 
                    and you agree that Perpetua will not be liable to you or any third party for any such termination.  
                    </Style.TermsListItems>
                   
                </Style.TermsUnorderedList>
            </Container>
            </Box>
        </>
    )
}

export default TermsConditions;