import * as React from 'react';
import { Box, Flex, Image, Link,Text} from '@chakra-ui/react';
import Heading from '../Heading/Heading';

//import{Link} from 'react-router-dom';
//import LinkButton from '../Button/LinkButton';
import Button from '../Button/CustomeButton';
import {
    ListItem,
    UnorderedList,
  } from '@chakra-ui/react'


function PriceCard2(props) { 
    return (
        <Box pos='relative' border={props.border}> 
       {props.cardTitle && <Heading lineHeight='0.9' title={props.cardTitle} variant='small' margin='0px 0px 30px' textAlign="center" />}

       <Flex textAlign={{sm:'center',md:'left'}} justifyContent={{sm:'center',md:'flex-start;'}} alignItems='center' minH='242px' width='100%' padding={{sm:'20px 10px ',md:'21px'}} backgroundColor='#F4F4F3' flexDirection={{sm:'column',md:'row'}}>
            <Box>
                {props.tagTitle && <Heading className='tag' variant='smallheading' as='small' lineHeight='0.9' title={props.tagTitle} ></Heading>}
                <Box width={{sm:"100%", md:"auto"}} padding={{sm:'0px',md:'23px 15px'}} marginBottom={{sm:'30px',md:'0'}} textAlign='center'>
                    <Heading  title={props.currency} variant='medium' lineHeight='0.9' />
                    <Heading title={props.offer} variant='smallheading' lineHeight='0.9' />
                </Box>
            </Box>
            <Box width={{sm:"100%", md:"auto"}} paddingLeft={{sm:"0px", md:"20px"}}>
                <Heading fontSize={{sm:"20px"}} lineHeight='0.9' title={props.planname} variant='smallmid' margin='0px 0px 10px'></Heading>
                {/* <Text fontSize={{sm:"14px", md:"16px"}} color="#7D7D7D" as='p' title={props.description} colors='Grey' width={props.width} margin="0 0 5px"></Text> */}

                <UnorderedList>
                    <ListItem>
                        <Text fontSize={{sm:"14px"}} color="#000" as='p' fontFamily='poppins'>Membership Perks</Text>
                    </ListItem>
                    <ListItem>
                        <Text fontSize={{sm:"14px"}} color="#000" as='p' fontFamily='poppins'>{props.guest} Guest Pass/Month</Text>
                    </ListItem>
                    <ListItem>
                        <Text fontSize={{sm:"14px"}} color="#000" as='p' fontFamily='poppins'>Add'l Credits-{props.credit}/Credit</Text>
                    </ListItem>
                </UnorderedList>
                <Box>
                {props.icon && <Box color='#D12C41' marginRight='5px' display='inline-flex' ><i className={props.icon}></i></Box>}
                    {props.freeze && <Heading variant='smallheading' as='small' lineHeight='0.9' title={props.freeze}></Heading>}
                </Box>
                <Link href={props.link} _hover="textDecoration:none" isExternal={props.targetBlank} >
                    <Button title={props.buttonTitle} color='#000' className="wow fadeInUp" margin='15px 0px 0px'/>

                </Link>
            </Box>
        </Flex>
      </Box>
      );
    }
export default PriceCard2;












