import React from 'react';
// import{Link} from 'react-router-dom';
import {Flex, Heading,Wrap,WrapItem,VStack,IconButton,useDisclosure,Link } from '@chakra-ui/react';
import Navigation from '../Menu/Navigation';
import Button from '../Button/CustomeButton';
import { FiMenu } from "react-icons/fi";
import { defaultHeaderProps } from './Header.type';
function Header(props) {
  const { isOpen,onClose, onToggle } = useDisclosure()

  return (
    <Flex bg={"black"} alignContent="space-between" width={"full"}alignItems={"center"}justifyContent={"center"} paddingStart={{sm:'10px', md:"50px"}} paddingEnd={{sm:'5px', md:"50px"}} position='fixed' top="0" zIndex='11111'>
      {props.isLogo || props.isNavigation || props.isButton ? (   
        <Wrap width="100%" className='headerWrap -hader'>
          {props.isLogo &&
            <WrapItem maxW={{base:'100%', md:'100%', lg:"15%",xl:"10%"}} flex="1 1 auto"  textAlign={{lg:"center", xl:"left"}}  height={{base:'70px', md:'70px', lg:"90px"}} alignItems={"center"} justifyContent={"left"} className='logo-section'>
              <Heading className='_logo' as={"h1"} color={"white"} fontSize={{sm:'xs', md:'md', lg:"lg",xl:"lg"}}><Link href='/' _hover="textDecoration:none">PERPETUA</Link></Heading>
            </WrapItem>
          }

          <WrapItem maxW={{base:'100%', md:'100%', lg:"83%",xl:"87%"}} flex="1 1 auto"  textAlign={{ lg:"center", xl:"left"}}  height={{base:'70px', md:'70px', lg:"90px"}} alignItems={"center"}
            justifyContent={"center"}>
            
            {props.isNavigation &&
              <VStack  align={{md:"left"}} width={{ sm:"50%", md:'92%'}} paddingLeft="41px" >
                <Navigation isDrawerOpen={isOpen} closeDrawer={onClose} />
              </VStack> 
            }
            {props.isButton &&
              <VStack align={{md:"left"}} flexDirection='row'>
                <Link href={props.link} isExternal={props.targetBlank} textDecoration='none' _hover="textDecoration:none">
                  <Button className='Download_APP' title='Download APP'  />
                </Link>
                <IconButton icon={<FiMenu display={"inline-block"}/>} className="_togelbuttn" maxW="30px" height="35px" display={{base:'inline-block', md:'inline-block', lg:'none'}} textAlign="center" padding={"0"} margin="0" onClick={onToggle}  />
              </VStack>
            }

          </WrapItem>

        </Wrap>
      ) : null}
    </Flex>
  );
}

export default Header;
Header.defaultProps = defaultHeaderProps
