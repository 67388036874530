import React from 'react';
import * as Style from './VideoCard.style'

const VideoCard = (props) => {
    return (
        <Style.HighroxVideo
            as='iframe'
            src={props.src}
            width={props.width}
            allow='autoplay'
            margin={props.margin}
            maxHeight={props.maxHeight}
            className='bannr_vdo'
        >
        </Style.HighroxVideo>

    );
}
export default VideoCard;
