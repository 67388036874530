
import {React, useState, useEffect, lazy, Suspense} from 'react';
import { Box,Image} from '@chakra-ui/react';


// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./OwlCarousel.css";
const OwlCarousel = lazy(()=>import('react-owl-carousel')) ;



function OwlCarouselComponent(){
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);

      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });
      const rowMargin = (windowSize[0] / 3) / 2;
      const rowMarginSm = (windowSize[0] / 3) - 50;



  return (
    <>
        <Box className='sl_section'  padding={{sm:"50px 0", md: "80px"}} >
            <Box className='sl_container' marginLeft={{sm:-rowMarginSm, md:-rowMargin }} marginRight={{sm:-rowMarginSm, md:-rowMargin }}>

                <Suspense fallback={"Loading.."}>
                    <OwlCarousel className='owl-carousel' items={3} center={true}  loop  nav dots={false} autoplay={true} autoplayTimeout="7000" margin={0}  >
                        <Image  alt='image' maxW="100%" src='/images/Rectangle_71.jpg'/>
                        <Image  alt='image' maxW="100%" src='/images/img00.png'/>
                        <Image  alt='image' maxW="100%" src='/images/img01.png'/>
                    </OwlCarousel>
                </Suspense>
            </Box>
        </Box>
        
       
    </>
  );
}

export default OwlCarouselComponent;
