import React,{useState, useEffect} from 'react';
import * as Style from './TeamFilter.style';
import { FaAngleDown } from "react-icons/fa";
import {Button,  } from '@chakra-ui/react';
import Heading from '../Heading/Heading';


const TabMenu = ({ filterItem, TabMenus, TabName, tabIndex }) => {
   
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const [priceTab, setPriceTab] = useState(isMobile ? false : true);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
        isMobile ? setPriceTab(false) : setPriceTab(true);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const handelTabItem = () => {
        setPriceTab(!priceTab);
    }

    return (

        <>
            <Style.SmallWraper>
                {isMobile && <Button onClick={handelTabItem} display='flex' alignItems='center' justifyContent='space-between' className='-small-dropdown-button'>
                    <Heading as='span' title={TabName}/>
                    <FaAngleDown/>
                </Button> }
            </Style.SmallWraper>

            {priceTab ? <Style.ButtonWrapper className="ptList ptListALL">
                {
                    TabMenus.map((curElem, index) => {
                        return <Style.FilterButon key={index} onClick={() => filterItem(curElem, index)} className={`tabBtn ${index === tabIndex ? "activeTab" : ""}`}>{curElem}</Style.FilterButon>
                    })
                }
            </Style.ButtonWrapper> : null}
        </>
    );
};

export default TabMenu;