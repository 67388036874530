import React, { useEffect, useState } from 'react';
import { Box, Container, SimpleGrid, Image, Flex, Center, Link, Spinner } from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import Heading from '../component/Heading/Heading';
import { NavLink } from 'react-router-dom';
import Text from '../component/SubHeading/SubHeading';
import Button from '../component/Button/CustomeButton';
import { Helmet } from "react-helmet";
import VideoCard from "../component/VideoCard/VideoCard";
import axios from 'axios';
import MetaTitle from './MetaTitle';
import LatestBlog from '../component/Blog/LatestBlog';
// import AppDownload from "../component/AppDownload/AppDownload";

function TeamDetails() {

    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [coachDetails, setCoachDetails] = useState([]);
    const [latestBlog, setLatestBlog] = useState([]);
    const [pageSlug, setPageSlug] = useState(null);

    const [isBlogAvail, setIsBlogAvail] = useState(false);
    const [isLatestl, setIsLatestl] = useState(false);

    useEffect(() => {

        setIsLoading(true);
        axios({
            method: 'get',
            url: process.env.REACT_APP_ADMIN_API_BASE_URL + '/coaches/single',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            params: {
                slug: slug
            },

        }).then(response => {
            setCoachDetails(response.data.data[0]);
            setIsLoading(false);

            if (response.data.data[0].has_blogs) {
                setIsBlogAvail(true);
            }

        }).catch(error => {
            console.log(error);
            setIsLoading(true);
        });

        setIsLoading(true);
        axios({

            method: 'get',
            url: process.env.REACT_APP_ADMIN_API_BASE_URL + '/blogs/latest',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            params: {
                num_of_post: 3,
                coach_slug: slug
            },

        }).then(response => {
            console.log(' response ...................', response.data);
            setLatestBlog(response.data);
            setIsLatestl(true)
            setIsLoading(false);

        }).catch(error => {
            console.log(' error gen ...................');
            console.log(error);
            setIsLoading(false);
        });

    },[]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <MetaTitle title='Team Details | Perpetua Fitness' />
            <Box minH={{ sm: '100vh', md: 'none' }}>
                {/* {isLoading ?
                    <Flex justifyContent='center'>
                        <Spinner
                            thickness='2px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='#d12c41'
                            size='lg'
                        />
                    </Flex>
                    :
                    <> */}
                <Box>
                    <Box
                        backgroundImage={{ md: (coachDetails.banner_image) !== 'null' ? coachDetails.banner_image : "url('/images/team-details-banner.jpg')" }}
                        backgroundColor={{ sm: 'black', md: 'none' }}
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        height={{ sm: 'auto', md: '350px', lg: '350px', xl: '450px' }}
                        width='100%'
                        position='relative'

                    >
                        <Container maxW='container.xl'>
                            <SimpleGrid
                                columns={{ sm: '1', md: '2' }}
                                width="full"
                                textAlign={{ sm: 'center', md: 'left' }}
                                padding={{ sm: '25px', md: '0' }}
                                gap={5}
                            >
                                <Box >
                                    <Image width='auto' height={{ md: '420px' }} src={(coachDetails.image) !== 'null' ? coachDetails.image : '/images/team-listing-defult.jpg'} margin={{ sm: '0 auto', md: '0' }} marginTop={{ sm: "0px", md: '100px' }} />
                                </Box>

                                <Box marginTop={{ sm: '5px', md: '100px' }} >
                                    {/* <Box backgroundColor='#D12C41' display='inline-block' margin={{ sm: '0 auto', md: '0' }} > <Text title={coachDetails.category_title} color="white" padding='2px 0 0' margin={{ sm: '5px 10px' }} /> </Box> */}
                                    <Heading title={coachDetails.name} color='white' variant='large' padding='15px 0' />
                                    {coachDetails.description && <Heading title={'"' + coachDetails.description + '"'} color='white'
                                        variant='smallmid' />}
                                    {/* {coachDetails.has_blogs &&
                                        <NavLink to={`/blog/by-coach/${coachDetails.slug}`}>
                                            <Text title={'Blogs By ' + coachDetails.name} cursor='pointer' color='#fff' padding='10px 0 0' textTransform='capitalize' fontSize={{ sm: '14px', md: '16px' }} />
                                        </NavLink>
                                    } */}

                                </Box>
                            </SimpleGrid>
                        </Container>
                    </Box>
                </Box>

                <Container maxW='container.xl' marginTop={{ md: "100px", lg: '100px', xl: '0px' }} padding={{ sm: '50px 15px', md: '150px 20px 0px' }}>
                    <SimpleGrid
                        columns={{ sm: '1', md: (coachDetails.playlist) ? 2 : 1 }}
                        width="full"
                        padding='0 0 15px 0'
                    >

                        <Box>
                            <Heading title={'About ' + coachDetails.name} width={{ sm: '100%', }} padding='0 0 15px' />
                            <Box className='htmlContent' dangerouslySetInnerHTML={{ __html: coachDetails.about }}></Box>
                        </Box>

                        {coachDetails.playlist &&
                            <Box paddingLeft={{ md: '30px' }}>
                                <Heading title='My go-to Spotify playlist' margin='0 0 15px' textAlign='left' variant='medium' />
                                <VideoCard src={coachDetails.playlist} width={{ sm: '100%' }}></VideoCard>
                            </Box>
                        }

                    </SimpleGrid>
                </Container>

                {/* <Box backgroundColor='black' padding={{ sm: '60px 10px 50px', md: '50px 0px' }} height='350px'>
                
                        </Box> */}
                {/* <Box>
                                <Container maxW='container.lg' padding={{ sm: '40px 15px 40px', md: '80px 20px' }}>
                                    <SimpleGrid
                                        columns={{ sm: '1', md: '2' }}
                                        width="full"
                                    >

                                        <Box>
                                            <Heading title='TRAIN WITH ME!' width={{ sm: '100%', md: '50%' }} padding='0 0 15px' />
                                        </Box>
                                        <Box>
                                            <Text title={coachDetails.additional_details} padding='0 0 25px' />
                                            <Link href={'mailto:' + coachDetails.email_id} >
                                                <Button title='Get in touch' color="black" />
                                            </Link>
                                        </Box>

                                    </SimpleGrid>
                                </Container>
                            </Box> */}


                {coachDetails.session &&
                    <Flex borderTop='1px solid' borderTopColor='LightGrey' >
                        <Container maxW={{ sm: 'Container.sm', md: 'container.md', lg: 'container.lg', xl: 'container.xl' }} padding={{ sm: '40px 0', md: '50px 0px', lg: '100px 0px' }}>
                            <Heading title={'NEXT SESSIONS WITH ' + coachDetails.name} variant='medium' textAlign='center' padding='0 0 30px' />
                            <Helmet>
                                <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></script>
                            </Helmet>
                            <healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id={coachDetails.session} data-widget-version="1"></healcode-widget>
                        </Container>
                    </Flex>
                }

                {(isBlogAvail && isLatestl) ?
                    <LatestBlog title={'Blogs By ' + coachDetails.name} coachBlogsSeeMore={coachDetails.slug} latestBlogs={latestBlog.data} />
                    : 'null'
                }



            </Box>
        </>
    )
}

export default TeamDetails;