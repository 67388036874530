import React from "react";
import { Flex, Box, Container, Stack, Text as PText, Image, UnorderedList, ListItem, SimpleGrid, GridItem, Link } from '@chakra-ui/react';
import Commoncard from '../component/Application/CommonCard';
import { CheckIcon } from '@chakra-ui/icons';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import MetaTitle from './MetaTitle';

function CrossfitCoach() {
    return (
        <>
            <MetaTitle title='Crossfit Coach | Perpetua Fitness' />
            <Container maxW='container.xl' padding={{ sm: '60px 5px 0px 10px', md: '70px 0 0', xl: '95px 0px 60px' }} >
                <Flex flexDirection={{ sm: "column", md: "row" }} >
                    <Box w={{ sm: "100%", md: "50%" }} h={{ sm: "auto" }} top={{ sm: "0", md: '100px' }} paddingLeft={{ sm: '20px', md: '30px' }} paddingBottom={{ sm: "30", md: '10px' }}>
                        <Box marginTop={{ lg: '50px' }}>
                            <Heading title='OPEN POSITION' cfSize='20px' lineHeight='1'></Heading>
                            <Heading title='CROSSFIT COACH' cfSize={{ sm: '30px', md: '50px' }} lineHeight='1'></Heading>
                        </Box>
                        <Box marginTop={{ lg: '80px' }}>
                            <UnorderedList paddingBottom='30px' m='10px 0px 0px' width={{ base: '90%', sm: '90%', md: '70%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='300' >
                                <ListItem borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative" paddingLeft="30px"
                                    _after={{ w: '10px', h: '10px', position: 'absolute', content: '""', top: '15px', left: '0', background: 'Red', borderRadius: '50px' }}>
                                    <Heading title='FULL-TIME / PART-TIME' cfSize="20px" fontWeight='500'></Heading>
                                    <Text title='Full-Time' />
                                </ListItem>
                                <ListItem borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative" paddingLeft="30px"
                                    _after={{ w: '10px', h: '10px', position: 'absolute', content: '""', top: '15px', left: '0', background: 'Red', borderRadius: '50px' }}>
                                    <Heading title='LOCATION' cfSize="20px" fontWeight='500' />
                                    <Text title='Dublin City' />
                                </ListItem>
                                <ListItem borderBottom="1px solid #DFDFDF" padding='10px 0px' position="relative" paddingLeft="30px"
                                    _after={{ w: '10px', h: '10px', position: 'absolute', content: '""', top: '15px', left: '0', background: 'Red', borderRadius: '50px' }}>
                                    <Heading title='PERKS' cfSize="20px" fontWeight='500' />
                                    <Text title='Flexible working hours, Company events & social hours and Gym and Studio access ' />
                                </ListItem>
                            </UnorderedList>
                        </Box>
                    </Box>
                    <Box w={{ sm: "100%", md: "45%" }} >
                        <Stack>
                            <Image src='/images/application1.png'></Image>
                            <Image src='/images/application2.png'></Image>
                        </Stack>
                    </Box>
                </Flex>
            </Container>

            <PText width={{ sm: '90%', md: "90%" }} borderTop="1px solid #DFDFDF" padding={{ md: "0px 0px 40px" }} margin='0 auto' marginTop={{ sm: '50px', md: '30px' }} marginBottom={{ sm: '20px', md: '0px' }} />
            {/* //////// */}


            <Box marginTop='40px'>
                <Container maxW='container.xl'>
                    <SimpleGrid
                        columns={{ sm: '1', md: '2' }}
                        columnGap={0}
                        rowGap={2}
                        width="full" >
                        <GridItem borderRight={{ sm: '0', md: "2px solid #DFDFDF" }} position='relative' >
                            <PText width='25px' marginTop='8px' top='0' right='0' height='1' position='absolute' marginRight='10px' borderTop={{ sm: '0', md: '1px solid black' }}                               >
                            </PText>
                            <Box width='15px' height='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' right='-9px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                            </Box>
                            <Box paddingLeft={{ sm: '7px', md: '0' }} paddingBottom={{ sm: '20px', md: '0' }}>
                                <Heading
                                    title='WHO WE ARE'
                                    textAlign={{ sm: 'left', md: "right" }}
                                    fontSize={{ sm: '30px', md: '36px', lg: '50' }}
                                    lineHeight="1"
                                    width='90%'
                                    padding={{ sm: '0px 0px 16px', md: '0px 5px' }}
                                    margin={{ md: '-15px 0 0 0 ' }}
                                />

                                <Text
                                    title="At PERPETUA, we are obsessed with coaching and providing an experience that each member will continue to talk about. We eat, drink, and sleep coaching. The job of our coaches is to provide the best experience to our members every single day. Early alarms and late nights don't phase us – we are focused and committed."
                                    width='90%'
                                    textAlign={{ sm: 'left', md: "right" }}
                                    padding={{ md: '20px 5px 10px' }}
                                />
                                <Text
                                    title="In an industry plagued with mediocrity, we strive to set a higher bar. Simply put we are professionals, and we are investing heavily in progressing our coaches to new heights."
                                    width='90%'
                                    textAlign={{ sm: 'left', md: "right" }}
                                    padding={{ md: '10px 5px' }}
                                />

                                <Text
                                    title='Through a culture of excellence, embracing what it means to represent yourself in the best possible way, and a dynamic development framework we arm our coaches with the tools and resources they need to excel and evolve.'
                                    width='90%'
                                    textAlign={{ sm: 'left', md: "right" }}
                                    padding={{ md: '10px 5px' }}
                                />
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                    <Flex>
                        <SimpleGrid
                            columns={{ sm: '1', md: '2' }}
                            columnGap={0}
                            rowGap={2}
                            width="full"
                        >
                            <GridItem>
                            </GridItem>
                            <GridItem borderLeft={{ sm: '0', md: "2px solid #DFDFDF" }} position='relative'>
                                <PText width='25px' marginTop='8px' top='0' left='0' height='1' position='absolute' marginLeft='10px' borderTop={{ sm: '0', md: '1px solid black' }}                              >
                                </PText>
                                <Box width='15px' height='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' left='-8px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                                </Box>
                                <Box paddingLeft={{ sm: '7px', md: '40px' }}>
                                    <Heading
                                        title='Join our Crossfit Coach Team!'
                                        cfSize={{ sm: '30px', md: '36px', lg: '50px' }}
                                        textAlign={{ sm: 'left', md: "left" }}
                                        lineHeight="1"
                                        width={{ sm: '100%', md: '90%' }}
                                        marginTop={{ md: '-20px' }}
                                        paddingBottom={{ sm: '15px', md: '25px' }}
                                        paddingLeft={{ md: '24px' }}
                                        textTransform='uppercase'
                                    />

                                    <Text
                                        title='We are seeking a highly motivated and experienced CrossFit Coach to join our fitness
                                        facility. As a CrossFit Coach, you will be responsible for leading and instructing CrossFit
                                        classes, and ensuring the safety and progress of our members. You will be a key player in
                                        fostering a positive and supportive training environment, helping individuals of all fitness
                                        levels achieve their health and wellness goals.'
                                        width='90%'
                                        textAlign="left"
                                        padding={{ sm: '10px 0 0', md: '20px 25px 0' }}
                                    />

                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                    <Flex>
                        <SimpleGrid
                            columns={{ sm: '1', md: '2' }}
                            columnGap={0}
                            rowGap={2}
                            width="full"
                        >
                            <GridItem borderRight={{ sm: '0', md: "2px solid #DFDFDF" }} position='relative' >
                                <PText width='25px' marginTop='8px' top='0' right='0' height='1' position='absolute' marginRight='10px' borderTop={{ sm: '0', md: '1px solid black' }}                               >
                                </PText>
                                <Box width='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' right='-9px' height='15px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                                </Box>
                                <Box paddingLeft={{ sm: '0px', md: '0' }}>

                                    <Heading
                                        title='Crossfit Coach Team Responsibilities'
                                        textAlign={{ sm: 'left', md: "right" }}
                                        cfSize={{ sm: '30px', md: '36px', lg: '50px' }}
                                        lineHeight="1"
                                        width='90%'
                                        margin={{ sm: '30px 0 0', md: '-15px 0 0' }}
                                        padding={{ sm: '10px 10px 20px', md: '0 0 20px' }}

                                    />

                                    <UnorderedList textAlign={{ sm: 'left', md: "right" }} paddingRight={{ md: '65px' }} m='0px' width={{ base: '90%', sm: '100%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal'>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            CrossFit Class Instruction - Lead engaging and dynamic CrossFit classes, demonstrating proper techniques and form.  <Box><CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' /></Box>
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            Design workouts that cater to various fitness levels and abilities, providing modifications when necessary. <Box> <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' /></Box>
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            Motivate and inspire participants to reach their full potential during each session.   <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='7px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            Ensure the safety of all participants, correcting form and technique to prevent injuries.  <Box> <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' /> </Box>
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Personal Training - Conduct individual assessments and consultations to understand members' fitness goals and limitations. <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Create personalized workout programs tailored to each member's needs and objectives.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Monitor progress regularly and make adjustments to the training plan as required.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Member Engagement and Support - Build strong relationships with members, providing ongoing support, encouragement, and feedback.<CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Offer nutritional guidance and lifestyle advice to enhance members' overall fitness journey.   <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Address questions and concerns promptly, demonstrating excellent customer service.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Equipment and Facility Maintenance - Ensure that all CrossFit equipment is well-maintained, clean, and safe for use.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Report any equipment issues or maintenance needs to management promptly.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Continuous Learning and Development - Stay updated with the latest trends, research, and best practices in CrossFit training.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', xl: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                        Attend workshops, seminars, and certifications to enhance coaching skills.    <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='10px' color='#D3D3D3' />
                                        </ListItem>
                                    </UnorderedList>

                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                    <Flex>
                        <SimpleGrid
                            columns={{ sm: '1', md: '2' }}
                            columnGap={0}
                            rowGap={2}
                            width="full"
                        >
                            <GridItem>
                            </GridItem>
                            <GridItem
                                position='relative' _after={{ position: 'absolute', content: '""', width: { md: '2px' }, height: '90%', top: '0', left: '0', background: '#DFDFDF' }}
                            >
                                <PText width='25px' marginTop='8px' top='0' left='0' height='1' position='absolute' marginLeft='10px' borderTop={{ sm: '0', md: '1px solid black' }}                              >
                                </PText>
                                <Box width='15px' height='15px' zIndex='1' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' left='-8px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                                </Box>
                                <Box paddingLeft={{ sm: '7px', md: '40px' }}>
                                    <Heading
                                        title='Minimum Requirements'
                                        fontSize={{ sm: '30px', md: '36px', lg: '50px' }}
                                        textAlign={{ sm: 'left', md: "left" }}
                                        lineHeight="1"
                                        width={{ sm: '100%', md: '90%' }}
                                        margin={{ sm: '20px 0 0', md: '-20px 0 0' }}
                                        padding={{ sm: '0 0 15px ', md: '0 24px 25px' }}
                                    />

                                    <UnorderedList textAlign="left" paddingLeft={{ md: '22px' }} paddingBottom='30px' m='0px' width={{ base: '90%', sm: '100%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal' >
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />
                                            CrossFit Certification - Possess a valid and recognized CrossFit coaching certification (e.g., CrossFit Level 1 or higher).
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />Previous Coaching Experience - Proven experience as a CrossFit coach or similar role, demonstrating the ability to lead effective and motivating classes.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' /> Fitness and Health Knowledge - A strong understanding of anatomy, exercise physiology, and nutrition.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' /> Knowledge of injury prevention and the ability to provide safe workout modifications.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />Communication and Leadership - Excellent communication skills to effectively instruct and engage with members.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />Ability to lead and inspire a diverse group of individuals.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />Passion for Fitness - A genuine passion for CrossFit and promoting a healthy lifestyle.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />Interpersonal Skills - Approachable, friendly, and able to create a positive and inclusive training atmosphere.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />Time Management - Punctuality and the ability to manage class schedules effectively.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' />First Aid and CPR Certification - Current certification in First Aid and CPR, or willingness to obtain it before starting employment.
                                        </ListItem>
                                        <ListItem padding='5px 0px' display='flex'>
                                            <CheckIcon marginRight='10px' color='#D3D3D3' /> This job specification serves as a general guideline for the role of a CrossFit Coach and can be adjusted based on the specific needs and requirements of the fitness facility.
                                        </ListItem>

                                    </UnorderedList>
                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                    <Flex paddingBottom={{ sm: '50px', md: '70px' }} marginTop={{ md: '-90px' }}>
                        <SimpleGrid
                            columns={{ sm: '1', md: '2' }}
                            columnGap={0}
                            rowGap={2}
                            width="full"
                        >
                            <GridItem position='relative' _before={{ position: 'absolute', content: '""', width: { md: '2px' }, height: '100%', top: '0', right: '0', background: '#DFDFDF' }} >
                                <PText width='25px' marginTop='8px' top='0' right='0' height='1' position='absolute' marginRight='10px' borderTop={{ sm: '0', md: '1px solid black' }}                               >
                                </PText>
                                <Box width='15px' borderRadius='50px' boxShadow={{ sm: '0', md: '0px 0px 0px 5px rgba(209,44,65,0.3)' }} top='0' right='-7px' height='15px' position='absolute' backgroundColor={{ sm: '', md: 'Red' }}>
                                </Box>
                                <Box paddingLeft={{ sm: '7px', md: '0' }}>

                                    <Heading
                                        title='Benefits '
                                        textAlign={{ sm: 'left', md: "right" }}
                                        cfSize={{ sm: '30px', md: '36px', lg: '50px' }}
                                        lineHeight="1"
                                        width='90%'
                                        margin={{ md: '-15px 0 0' }}
                                        padding={{ sm: '0 0 10px', md: '0 0 20px' }}
                                    />



                                    <UnorderedList textAlign={{ sm: 'left', md: "right" }} paddingRight={{ md: '65px' }} m='0px' width={{ base: '90%', sm: '100%' }} listStyleType='none' color='black' fontFamily='Poppins' fontWeight='normal' >
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', md: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            Flexible working hours  <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft={{ md: '10px' }} color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', md: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            Company events & social hours <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='2px' color='#D3D3D3' />
                                        </ListItem>
                                        <ListItem padding='5px 0px' flexDirection={{ sm: 'row-reverse', md: 'row' }} display={{ sm: 'flex', md: 'block' }} justifyContent={{ sm: 'flex-end', md: 'flex-start' }}>
                                            Gym and Studio access   <CheckIcon marginRight={{ sm: '10px', md: '0' }} marginLeft='2px' color='#D3D3D3' />
                                        </ListItem>

                                    </UnorderedList>

                                </Box>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Container>
            </Box>



            {/*  application  */}
            <Commoncard
                backgroundColor='rgb(238, 238, 238)'
                width={{ sm: '90%', md: '60%' }}
                fontSizeHeading={{ sm: '30px', md: '50px' }}
                fontSize='20px'
                color='#585858'
                padding='20px 0px'
                textAlign='center'
                title='JOIN TEAM Perpetua'
                subHeading="Full and part-time positions are available."
                subtitle={<Box>To apply, download and print the application below. Please fill out, scan, and email your completed application to <Link href='mailto:hello@perptua.ie' textDecoration='underline' > hello@perpetua.ie</Link> with the job title as subject line</Box>}
                buttontitle='Application'
                buttonLink = 'careers'
                isButton={true}
            />
        </>
    )
}
export default CrossfitCoach;