import React from 'react';
import {Box,Flex,Container,VStack,HStack,WrapItem,Wrap,Image,Link} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import Button from '../Button/CustomeButton';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
import{defaultAppDownloadProps} from './AddDownload.type'
import MyImage from '../LazyLoadImage/MyImage';

function AppDownload(props) {
  return (
    <Flex 
    className={props.className}
    bg={props.bg} 
    alignContent="space-between" 
    width={"full"}
    alignItems={"center"}
    justifyContent={"center"} 
    position='relative'
    backgroundSize='cover'
    backgroundPosition='center'
    backgroundRepeat='no-repeat'
    p={{sm:"30px 0", md: "80px 0"}}
    
    >
        <Container maxW='container.xl'>
            <Wrap width={"100%"} minH={{sm:'500px', md:'none'}} marginBottom={{sm:'30px', md:'0px' ,xl:'0px'}} >
            {props.isAppImage &&

                <WrapItem  maxW={{base:'100%',  md:'40%', lg:"40%",xl:"40%"}} flex="1 1 auto"  textAlign={{ base:'center',md:"left", xl:"left"}} alignItems={"center"}
                    justifyContent={{ base:'center',md:"left", xl:"left"}} paddingLeft={{base:'0px', md:'50px'}} position='relative' zIndex='1' padding={props.padding}
                    className='wow animate fadeInUp' 
                >
                    {/* <Image  alt={props.alt} maxW={{sm:'80%', md:'90%', xl:'90%'}} src={props.imageUri}/> */}
                    <MyImage alt={props.alt} src={props.imageUri} width={props.width} height={props.height}  />
                </WrapItem>
            }
                <WrapItem maxW={{base:'100%', md:'55%', lg:"55%",xl:"55%"}} flex="1 1 auto"  textAlign={{ base:'center', md:"left"}}  alignItems={"center"}
                    paddingLeft={{sm:'0px', md:'80px'}} paddingTop={{sm:'50px', md:'0px'}} 
                    className='wow animate fadeInUp'
                >
                        <VStack spacing={2}align='stretch' width={"full"}>
                            
                            
                            <Box color='white' width={"100%"} >
                            {props.isHeading && < Heading 
                                                    as={"h2"} 
                                                    textAlign={{sm:"center", md:"left"}} 
                                                    textTransform='uppercase' 
                                                    margin={{base:"10px 0px ", lg:"15px 0px ",xl:"25px 0px"}} 
                                                    variant='large'
                                                    lineHeight={{ base:'30px' , md:'30px' , lg:'30px', xl:'45px'}}  
                                                    color={props.color}
                                                    title={props.heading}
                                                    >
                                    {/* Your Daily Dose of Online Workouts Is Live */}
                                </Heading>
                            }
                            {props.isSubheading &&    <Text as="p" textTransform='initial' margin='0px 0px 30px' fontSize='xs' fontFamily='poppins' color={props.color} title={props.para}>
                                    {/* The Best Online Training Platform  */}
                                </Text>
                            }
                            {props.isAppButton &&
                                <HStack spacing='10px' marginBottom={{base:'5px', lg:"10px",xl:"15px"}} justifyContent={{ base:'center', md:"left"}}>
                                    <Box display='inline-block' >
                                    <Link href={props.appstorelink} target='_blank' _hover={{textDecoration:'none'}}> 
                                        <Image  alt={props.alt2} src={props.appstore} className="button-image"/>
                                    </Link>
                                    </Box>
                                    <Box display='inline-block' >
                                        <Link href={props.googleplaylink} target='_blank' _hover={{textDecoration:'none'}}>
                                            <Image  alt={props.alt3} src={props.googleplay} className="button-image"/>
                                        </Link>
                                    </Box>
                                </HStack>
                            }
                            {props.isButton &&    <NavLink to={props.link} isExternal={props.targetblank}  _hover={{textDecoration:'none'}}>
                                    <Button title='Get Started'/>
                                </NavLink>
                            }
                            </Box>
                            
                        </VStack>
                </WrapItem>
            </Wrap>
        </Container>
    </Flex>
  )
}

export default AppDownload;
AppDownload.defaultProps = defaultAppDownloadProps
