import React, { useState } from 'react';
import { Heading as HeadingChakra } from '@chakra-ui/react'
import {defaultHeadingProps} from './Heading.type'

const Heading = (props) => {
    const [fontSize, setFontSize] = useState(null)

    React.useEffect(() => {
        switch (props.variant) {

            // we use font size base responsive variant 
            case 'largheading':
                setFontSize({ sm:'md' , md:'lg' , lg:'xl', xl:'xxl'})  //start form 90px fontsize                
                break;
            case 'extralarge':
                setFontSize({ sm:'md' , md:'md' , lg:'lg', xl:'xl'})  //start form 60px fontsize
                break;
            case 'large':
                setFontSize({ sm:'md' , md:'md' , lg:'lg', xl:'xl2'}) //start form 55px fontsize
                break;
            case 'medium':
                setFontSize({ sm:'sm' , md:'md' , lg:'lg'}) //start form 40px fontsize
                break;
            case 'small':
                setFontSize({sm:'sm2' , md:'sm' , lg:'md'}) //start form 30px fontsize
                break; 
            case 'smallmid':
                setFontSize({sm:'xs' , md:'sm'}) //start form 25px fontsize
                break;     
            case 'extrasmall':
                setFontSize({sm:'xs' , md:'sm2'}) //start form 20px fontsize
                break; 
            case 'smallheading': 
                setFontSize({sm:'xs' , md:'xs'}) //start form 16px fontsize                
                break;    

            default:
                setFontSize({ sm:'md' , md:'md' , lg:'lg', xl:'xl'}) //start form 60px fontsize
        }
    },[props])

    return (
        <>
            <HeadingChakra 
                as={props.as} 
                color={props.color} 
                fontSize={props.cfSize ? props.cfSize : fontSize} 
                lineHeight={props.lineHeight} 
                textTransform="uppercase" 
                p={props.padding} 
                m={props.margin}
                width={props.width}
                textAlign={props.textAlign}
                display={props.display}
                textDecoration={props.textDecoration}
                className={props.className}
                mb={props.marginBottom}
                letterSpacing='normal'
                >

                    {props.title}
                    
            </HeadingChakra>
        </>
    );
}

export default Heading;
Heading.defaultProps = defaultHeadingProps