import React from "react";
import { Box } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react';
import * as Style from './ModalSignUp.style';
import { Helmet } from "react-helmet";

function ModalSignUp() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const nltrClose = () => {
        onClose();
        window.location.reload(false);
    }



    return (
        <>
            <Box marginLeft={{ lg: "25px", xl: "70px" }} marginBottom='35px'>
                <Style.ButtonModalSignUp onClick={onOpen}>Join Our Newsletter</Style.ButtonModalSignUp>

                <Modal isOpen={isOpen} onClose={nltrClose} size={{ sm: 'sm', md: 'md' }} >
                    <ModalOverlay />
                    <ModalContent>
                        <Box background='white' padding='10px 0 40px'>
                            <ModalHeader fontSize='25px' textAlign='center' >JOIN OUR NEWSLETTER</ModalHeader>
                            <ModalCloseButton color='black' />

                            <ModalBody>
                                <Helmet>
                                    <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></script>
                                </Helmet>
                                <healcode-widget data-type="prospects" data-widget-partner="object" data-widget-id="70466760014" data-widget-version="0" ></healcode-widget>
                            </ModalBody>
                        </Box>
                    </ModalContent>
                </Modal>
            </Box>

        </>
    )
}
export default ModalSignUp;