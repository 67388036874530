export const defaultCaptionProps = {
    title: 'Default Heading Caption',
    color:'#fff',
    fontSize:'xl',
    lineHeight:"1",
    padding:'0',
    paddingBottom:'20px',
    margin:'0',

    isSubtext: true,
    isHeading: true,
    isButton: true,
    issubtitleRight:false,
    issubtextpara :false, 
    isButton2:false,
}
