import { Box} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";


export const HighroxVideo = styled(Box)(props => ({
    borderRadius:'15px',
    overflow:'hidden',
    maxHeight:`(${props.maxHeight})`,
    aspectRatio:'16/8',

    "@media screen and (max-width:767px)": {
        aspectRatio:'16/4',
    },

    "@media screen and (max-width:640px)": {
        aspectRatio:'16/5',
    },

    "@media screen and (max-width:480px)": {
        aspectRatio:'16/6',
    },
    "@media screen and (max-width:400px)": {
        aspectRatio:'16/4',
    },

}))
