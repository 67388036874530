import React from 'react'
import * as Style from '../ContentBox/contentBox.style';


import Heading from '../../Heading/Heading';
import Text from '../../SubHeading/SubHeading';

const ContentBox = (props) => {
  return (
    <Style.BoxWrap className='box'>
        <Style.ImageBox backgroundImage={props.Background}>
          <Style.HeadingWrapper className='heading'>
            <Heading title={props.BoxHeading} variant="medium" margin="0px" color='#fff'/>
          </Style.HeadingWrapper>
        </Style.ImageBox>
        <Style.ParaWrapper className='subheading' href={props.Link}>
            <Text title={props.BoxDescription} as='span' fontSize="16px"color='#fff' lineHeight={{md:'1.4',lg:'1.8'}} />
        </Style.ParaWrapper>
    </Style.BoxWrap>
  )
}

export default ContentBox