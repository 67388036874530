import React from 'react'
import {Container,Link,Text} from '@chakra-ui/react';
import * as Style from './LandingStyle.style';



const UnbrokinLandingpage = () => {
  return (
    <>
        <Style.PageBanner backgroundImage={{sm:'url(./images/UB_Banner.webp)' ,md:'url(./images/UB_Banner.webp)'}} href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107777" target='_blank'></Style.PageBanner>

        <Style.CenterContent>
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                <Style.CenterContentWrapper marginBottom='20px'>
                    <Style.CenterContentHeader>
                        <Style.h2 variant='medium' title='Get ready to go ‘Unbroken Powered by lululemon’ '/>
                        <Style.p title='8 Studio Classes. 8 Days. A reward from lululemon for all your hard work.' fontSize={{lg:'16px', xl:'18px'}}/>
                    </Style.CenterContentHeader>
                    <Style.p title='Complete the challenge and take home your very own pair of lululemon shorts or leggings as a reward.'></Style.p>
                    <Style.p title='This is one you don’t want to miss.'></Style.p>
                </Style.CenterContentWrapper>
                <Style.pageButton href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107777' target='_blank'>I’m ready</Style.pageButton>
            </Container>
        </Style.CenterContent>

        <Style.midContent>
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                <Style.midcontentWrapper>
                    <Style.imageBox>
                        <Style.image alt='image' src="./images/UB-left-image.jpg"/>
                    </Style.imageBox>
                    <Style.contentBox>
                        <Style.h2 variant='small' title='Want to know what it takes to complete the challenge and earn your reward? ' marginBottom='20px'/>
                        {/* <Style.description title={props.subHeading} fontSize={{lg:'16px', xl:'18px'}}/> */}
                        <Style.pointList>
                            <Style.pointlistItem>Grab your Unbroken Challenge Pack <Link href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107777" textDecoration='underline' target='_blank'>here</Link> and use it to book into class every day from October 14th – 21st.  </Style.pointlistItem>
                            <Style.pointlistItem>Once you’ve purchased your Challenge Pack, register for your reward from lululemon by completing the form <Link href="https://6ys5dtrn9e0.typeform.com/Unbroken" textDecoration='underline' target='_blank'>here</Link> </Style.pointlistItem>
                            <Style.pointlistItem>Book yourselves into the same class </Style.pointlistItem>
                            <Style.pointlistItem>HIIT the studio every day for 8 days and get your Unbroken stamp card marked for each class that you do (the important part!).</Style.pointlistItem>
                            <Style.pointlistItem>On Day 8, you’ll receive your lululemon voucher redeemable at Grafton St. for women’s leggings or men’ shorts </Style.pointlistItem>
                            <Style.pointlistItem>For everyone that takes part, we’ll be hosting our Unbroken studio party in Lennox Street from 6pm so you can celebrate all your hard work over the challenge and meet your studio buddies. </Style.pointlistItem>
                        </Style.pointList>
                        <Style.pageButton href='https://6ys5dtrn9e0.typeform.com/Unbroken' target='_blank' marginTop='25px'>Kit me out</Style.pageButton>
                    </Style.contentBox>
                </Style.midcontentWrapper>
            </Container>
        </Style.midContent>

        <Style.faqSection>
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                <Style.h2 variant='extralarge' title='FAQS' textAlign='center' />
                <Style.p title='Check out our FAQ below for all the juicey details:' fontSize='18px' paddingBottom='30px' textAlign='center'/>

                <Style.faqBox allowToggle>
                    <Style.faqItem >
                        <Style.itemButton>
                            <Style.h2 variant='extrasmall' title="What is Unbroken? "/>
                            <Style.itemIcon/>
                        </Style.itemButton>
                        <Style.itemArea>
                            <Text fontFamily='Poppins' fontSize='16px'>A challenge for your mind & body (with a spicey reward!). A challenge that originated back in 2020 which encourages you to smash 8 studio classes in 8 days. Your Unbroken Challenge pack includes Tread & Shred, Ride, Rumble and HYROX! The perfect way to add some motivation into your fitness routine. Get sweaty and challenge yourself in different classes and get to know new coaches.  </Text>

                            <Text fontFamily='Poppins' fontSize='18px' fontWeight='bold' marginTop='15px'>How it works:  </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Grab your UNBROKEN Class pack - 8 credits for €88 <Link href="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107777" textDecoration='underline' target='_blank'>here</Link>  and use it to book into one class each day from October 14 – 21. This is the perfect chance to try out different classes and coaches. Commit to yourself and smash it! </Text>
                            <Text fontFamily='Poppins' fontSize='16px' fontWeight='500' marginBottom='10px'>See how far you can get in the 8-day challenge! Participants get an exclusive UNBROKEN goody bag along with a ticket to our exclusive event on Day 8 in our Lennox Street studio.  </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive a voucher to redeem a pair of lululemon shorts or leggings lululemon Grafton Street. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' fontWeight='500' marginBottom='10px'>To participate in the challenge, you must have an Unbroken class pack.  </Text>
                        </Style.itemArea>
                    </Style.faqItem>

                    <Style.faqItem >
                        <Style.itemButton>
                            <Style.h2 variant='extrasmall' title="Can I use my regular credits to take part?"/>
                            <Style.itemIcon/>
                        </Style.itemButton>
                        <Style.itemArea>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Only classes attended under your 8 unbroken credits will count towards the challenge. All other studio credit packs and studio memberships will be put on hold while you complete the challenge. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Save your regular credits for after! </Text>
                        </Style.itemArea>
                    </Style.faqItem>

                    <Style.faqItem >
                        <Style.itemButton>
                            <Style.h2 variant='extrasmall' title="What do I get for completing all 8 Days?"/>
                            <Style.itemIcon/>
                        </Style.itemButton>
                        <Style.itemArea>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>(Apart from bragging rights)  </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive a voucher to redeem a pair of lululemon shorts or leggings lululemon Grafton Street along with a ticket to our exclusive event on Day 8 in our Lennox Street studio. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' fontWeight='500'>Participants who take part will still receive an exclusive UNBROKEN goody bag along with a ticket to our exclusive event on Day 8 in our Lennox Street studio. </Text>
                        </Style.itemArea>
                    </Style.faqItem>

                    <Style.faqItem >
                        <Style.itemButton>
                            <Style.h2 variant='extrasmall' title="What’s the ‘Unbroken’ party?"/>
                            <Style.itemIcon/>
                        </Style.itemButton>
                        <Style.itemArea>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>We’ll be hosting an exclusive Unbroken party for ALL of our studio guests in Lennox Street studio. Whether you completed the challenge, took part or supported your studio buddy along the way, you’re invited. Keep an eye on your inbox for your e-invite! </Text>
                        </Style.itemArea>
                    </Style.faqItem>

                    <Style.faqItem >
                        <Style.itemButton>
                            <Style.h2 variant='extrasmall' title="What’s the ‘Unbroken’ party?"/>
                            <Style.itemIcon/>
                        </Style.itemButton>
                        <Style.itemArea>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>We’ll be hosting an exclusive Unbroken party for ALL of our studio guests in Lennox Street studio. Whether you completed the challenge, took part or supported your studio buddy along the way, you’re invited. Keep an eye on your inbox for your e-invite! </Text>
                        </Style.itemArea>
                    </Style.faqItem>
                </Style.faqBox>    

            </Container>
        </Style.faqSection>

        <Style.CenterContent>
            <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                <Style.CenterContentWrapper>
                    <Style.CenterContentHeader>
                        <Style.h2 variant='medium' title='TERMS & CONDITIONS'/>
                        <Text fontFamily='Poppins' fontSize='18px' marginBottom='10px' marginTop='20px' fontWeight='700'>Do you accept the challenge? </Text>
                        <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' >UNBROKEN Class Packs are valid for use from 14 - 21 Oct. Credits cannot be extended or paused. You must use the UNBROKEN Class Credits to participate in the challenge and register <Link href="https://6ys5dtrn9e0.typeform.com/Unbroken" textDecoration='underline' target='_blank'>here</Link> to avail of your gift. Any other studio credit packs and studio memberships will be paused while you complete the challenge. ClassPass credits do not count towards the challenge.</Text>
                        <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' >Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox Street locations.</Text>
                        <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' >To avail of your lululemon gift you must register via registration form here as well as having a valid Unbroken Powered by lululemon challenge pack. Colour based on availability. Gift redeemable from lululemon Grafton St. Store between 14th - 28th Oct only. No colour or style swaps.</Text>
                        <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' >Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox Street locations.</Text>
                        
                    </Style.CenterContentHeader>
                </Style.CenterContentWrapper>
            </Container>
        </Style.CenterContent>
    </>
  );
}

export default UnbrokinLandingpage;