import { extendTheme } from '@chakra-ui/react'
// const activeLabelStyles = {
//   transform: "scale(0.85) translateY(-24px)"
// };
const theme = extendTheme({
  fonts: {
    heading: `'BlenderProBold', sans-serif`,
    body: `'BlenderProBold', sans-serif`,
  },
  colors: {
    Red: "#D12C41",
    LightGrey:"#EEEEF1",
    Grey:"#686868",
    Deepgre:"#7D7D7D",
    white:"#ffffff",

  },
  fontSizes: {
    xs: "16px",
    sm: "25px",
    md: "30px",
    lg: "40px",
    xl: "60px",
    lg2:"45px",
    xs2:"18px",
    sm2:"20px",
    xl2: "55px",
    xxl:"86px",
  },
  breakpoints: {
    sm: "320px",//screen 768 to 320
    md: "768px",//screen 960 to 768
    lg: "992px", //screen 1199 to 992
    xl: "1200px",//screen 1200 to 1439
    xxl: "1440px",// screen 1440 to 1920
  },
  container: {
    sm: '767px',
    md: '991px',
    lg: '1199px',
    xl: '1439px',
  },

})

export default theme