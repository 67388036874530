import { Flex,Heading,Text,Button} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const  ThankyouCard = styled(Flex)(props => ({
    display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column',
    minHeight:'300px',background:'#fff', width:'95%',maxWidth:'550px',margin:'0px auto',boxShadow:'0px 0px 23px -7px rgba(0,0,0,0.17)',borderRadius:'15px',gap:'15px',
}))

export const  Icon = styled(Heading)(props => ({
    
}))

export const  HeadingText = styled(Heading)(props => ({
textTransform:'uppercase'
}))

export const  Description = styled(Text)(props => ({
    "@media screen and (max-width:480px)": {
       width:'90%',margin:'0px auto'
    }
}))

export const  BackButton = styled(Button)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: '#000',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '180px',
    ":hover&": {
        background: '#D12C41',
        color:'#fff'
        
    },

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 15px',
        minWidth: '100px',
    }

}))

