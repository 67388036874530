
import React from 'react';
import Marquee from "react-fast-marquee";
import Heading from '../Heading/Heading';
import {ListItem, UnorderedList,} from '@chakra-ui/react'
function MarqueLoop(props){

    const marqueItemData = ["Results", "Facility", "Coaching", "Programs", "Friendly", "Nutrition"];



  return (
        <>

            <Marquee className='marquPanel' speed='100'>


                <UnorderedList>

                    { props.marqueItem ?
                        props.marqueItem.map((item, index) => {
                        return (
                            <ListItem 
                                key={index}
                                display='inline-block' 
                                padding='0px 20px' 
                                position='relative' 
                                className='_marque_list'
                                >
                                <Heading title={item} color='white' variant='extrasmall'></Heading>
                            </ListItem>
                        )})
                       : 
                       marqueItemData.map((item, index) => {
                        return (
                            <ListItem 
                                key={index}
                                display='inline-block' 
                                padding='0px 20px' 
                                position='relative' 
                                className='_marque_list'
                                >
                                <Heading title={item} color='white' variant='extrasmall' ></Heading>
                            </ListItem>
                        )})
                    }

                    

                </UnorderedList>
                <UnorderedList>

                    { props.marqueItem ?
                        props.marqueItem.map((item, index) => {
                        return (
                            <ListItem 
                                key={index}
                                display='inline-block' 
                                padding='0px 20px' 
                                position='relative' 
                                className='_marque_list'
                                >
                                <Heading title={item} color='white' variant='extrasmall' ></Heading>
                            </ListItem>
                        )})
                       : 
                       marqueItemData.map((item, index) => {
                        return (
                            <ListItem 
                                key={index}
                                display='inline-block' 
                                padding='0px 20px' 
                                position='relative' 
                                className='_marque_list'
                                >
                                <Heading title={item} color='white' variant='extrasmall'></Heading>
                            </ListItem>
                        )})
                    }

                </UnorderedList>
              




                {/* <UnorderedList>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Results' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Facility' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Coaching' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Programs' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Friendly' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Nutrition' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>

                </UnorderedList>
                <UnorderedList>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Results' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Facility' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Coaching' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Programs' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Friendly' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Nutrition' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>

                </UnorderedList>
                <UnorderedList>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Results' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Facility' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Coaching' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Programs' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Friendly' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>
                    <ListItem 
                        display='inline-block' 
                        padding='0px 20px' 
                        position='relative' 
                        className='_marque_list'
                        >
                        <Heading title='Nutrition' color='white' variant='extrasmall' margin='0px 20px'></Heading>
                    </ListItem>

                </UnorderedList> */}
               
            </Marquee>

        </>
  )
}
export default MarqueLoop;