import React from "react";
import * as Style from './LandinBanner.style';
import {Container} from '@chakra-ui/react';

import Heading from '../../Heading/Heading';

import { useDisclosure,Link, Image,Box } from '@chakra-ui/react';

// import * as Style from '../../VideoCard/VideoCard.style'
import Button from '../../Button/CustomeButton';




const LandingBanner = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
  return (

    <Style.Banner backgroundColor={{sm:'black'}} backgroundImage={{sm:'url(./images/location-banner.jpg)' ,md:'url(./images/location-banner.jpg)'}}>
        <Container maxW="container.xl">
       
            <Style.BannerWrapper>
                
            {props.banners.map((item,index)=>{
                return (
                    <Style.BannerCaption>
                        <Heading title={item.heading1} as='small' cfSize={{sm:'xs' , md:'xs'}} variant="smallheading" margin="0px 0px 5px" color='#fff'/>
                        <Heading title={item.heading2} as='h1' cfSize={{ sm:'md' , md:'md' , lg:'lg', xl:'xl'}} variant="extralarge" margin="0px 0px 5px" color='#fff'/>
                        <Heading title={item.heading3} as='h4' cfSize={{sm:'xs' , md:'sm2'}} variant="extrasmall" margin="0px 0px 5px" color='#fff' textAlign={{sm:'center'}}/>

                        <Style.ButtonGrup>
                            <Style.BannerButton href={item.Link1}>BOOK NOW</Style.BannerButton>
                            <Style.BannerButtonbg href={item.Link2} >GET IN TOUCH</Style.BannerButtonbg> 
                        </Style.ButtonGrup>
                    </Style.BannerCaption>
                )
             })}       
            </Style.BannerWrapper>
            
            
        </Container>
    </Style.Banner>

      



  )

}

export default LandingBanner
