import React from 'react';
import Heading from '../component/Heading/Heading';


function AboutPage() {
  return (
    <>
  <Heading></Heading>
    </>
  );
}

export default AboutPage;