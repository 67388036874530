import { Flex, Container,SimpleGrid,GridItem,Box,Stack,VStack,Link,Image } from '@chakra-ui/react';
import React, {useEffect} from 'react';
import Heading from '../component/Heading/Heading';
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import ClassCard from '../component/Classcard/ClassCard';
import AppDownload from '../component/AppDownload/AppDownload';
import Text from '../component/SubHeading/SubHeading';
// import{Link} from 'react-router-dom';
import Button from '../component/Button/CustomeButton';
import Testimonial from '../component/Testimonial/Testimonial';
// import CfProgram from '../component/CF Program/CfProgram';
import Accordian from '../component/Accordion/Accordian';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import BlogSection from '../component/Blog/BlogSection';

const accoData = [
  {
      "id": 1,
      "title": "When should I arrive for class?",
      "description": "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
  },
  {
      "id": 2,
      "title": "What do I do when I get there?",
      "description": "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
  },
  {
      "id": 3,
      "title": "What if I'm running late?",
      "description": "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
  },
  {
      "id": 3,
      "title": "Good Vibes Zone",
      "description": "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
  }

]
const accoData2 = [
  // {
  //     "id": 1,
  //     "title": "What is a RIDE Class like?",
  //     "description": "More than a spin class. An experience. Fully equipped with keiser MP3I bikes and The best lighting and sound system in Ireland. Our ride45/60, and rhythm ride classes offer something for everyone.",
  // },
  {
    "id": 1,
    "title": "What is a RHYTHM RIDE class like?",
    "description": "In a RHYTHM RIDE class, you can expect sprints, hills and jogs like any other spin class but.. with a little flavour on top. In RHYTHM RIDE you will mindfully move connecting to the beat and switching off any distractions. In this class you will move through choreographed sequences to the best tunes and even better motivation. Get ready to leave feeling inspired and excited for more.",
  },
  // {
  //   "id": 1,
  //   "title": "What is a RIDE 45 class like?",
  //   "description": "A RIDE45 class is the perfect blend of music, technology and indoor cycling. At its core, the music drives the workout plan created by our coaches. The use of the technology from the Keiser Group app immerses the participant in the class with visual feedback on their performance. The lighting in our studio enhances the experience, elevating these classes to the best in Dublin. You will leave dripping in sweat, with a sense of achievement and with a goal to beat in your next class.",
  // },
  {
      "id": 2,
      "title": "I've never done this style of training before, can I keep up?",
      "description": "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
  },
  {
      "id": 3,
      "title": "What should I wear?",
      "description": "Wear athletic clothes that are comfy for you to sweat and move in.",
  },
  {
      "id": 4,
      "title": "What shoes should I wear?",
      "description": "Most athletes choose to wear normal trainers or you can wear clip on cycling shoes",
  },
  {
      "id": 5,
      "title": "Do you guys provide water?",
      "description": "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
  }

]
const accoData3 = [
  {
      "id": 1,
      "title": "Do you have showers?",
      "description": "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
  },
  {
      "id": 2,
      "title": "That was fun! What's next?",
      "description": "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
  }

]

const metaTitle="Spin Class Dublin | Rhythm Ride | Perpetua Fitness"
const metaDesc="More than a spin class. An experience. Fully equipped with Keiser M3i bikes and the best lighting and sound system in Ireland. Book a class."


function RidePage(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>
      {/* <InnerBanner 
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/ride_m.jpg)', md:'url(./images/ride.jpg)' }}
        subtitle='RIDE STUDIO'
        title='RIDE 45 AND RHYTHM RIDE'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane & Lennox Street Facility</Box>}
        issubtextpara={false} 
        isButton={true} 
        issubtitleRight={true} 
        width={{sm:"100%" }}
        height={{ sm:'300px', md:'450px', lg:'500px', xl:'767px'}}
        buttontitle='BOOK A CLASS'
        Link='/pricing'
        ButtonModalmarginTop={{sm:'10px', md:'0'}}
        videoModal='https://www.youtube.com/embed/Q5v9N-zZckU'
        /> */}

        <VideoBanner 
          videobanner='../video/Ride-banner.mp4'
          title="RHYTHM RIDE"
          subtitle="SPIN CLASS DUBLIN"        
          isButton={true} 
          issubtextpara={false} 
          buttontitle="BOOK A CLASS"
          margin="0px 0 0"
          Link="/timetable"
          videoModal='https://www.youtube.com/embed/Q5v9N-zZckU' 
          targetBlank={false}
          modalButtontitle="View The Experience"
          subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane & Lennox Street Facility</Box>}
          issubtitleRight={true} 
        />

      {/* <Flex flexDirection='column' padding={{sm:'50px 0px',md:'70px 0px'}} background="#fff">
        <Container maxW='container.xl'>
          <Heading 
            as="h3" 
            title="MORE THAN A SPIN CLASS. AN EXPERIENCE. Fully equipped with Keiser M3I bikes and the best lighting and sound system in Ireland. Our RIDE45, and RHYTHM RIDE classes offer something for everyone."
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{lg:'100%', xl:'100%'}}
            padding={{sm:'0px 0px 50px', md:'0px 0px 70px' }}
            className='wow animate fadeInUp'
          >
          </Heading>

          <SimpleGrid 
            columns={{sm:'1',md:'2'}} 
            columnGap={10} 
            rowGap={2} 
            width="full" 
          >
              <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                <ClassCard
                    title={false}
                    subtitle='TURN OFF THE SCREENS, BRING THE LIGHTS DOWN LOW, AND JUST FEEL THE BEAT OF THE MUSIC.
                    DURING THIS 45 MINUTE FULL BODY EXPERIENCE, YOU WILL HIT CARDIO HIGHS, CHOREOGRAPHY
                    COMBOS AND A DUMBELL DEDICATED TRACK TO REALLY FOCUS ON YOUR UPPER BODY.'
                    bg="url('/images/ride45_new.jpg')"
                    link="/about"
                    isButton={false}
                    marginBottom='30px'
                    transform="uppercase"
                    minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                />
              </GridItem>
              <GridItem mt={{base:'20px', sm:'40px', xl:'84px'}} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
                <ClassCard
                    title={false}
                    subtitle='GET READY TO RIDE TO YOUR INSTRUCTOR’S PERFECTLY CURATED PLAYLIST AND LEAVE THE ROOM
                    LIGHTER AND MORE INSPIRED THAN WHEN YOU WALKED IN.'
                    bg="url('/images/rhythm_ide.jpg')"
                    link="/about"
                    isButton={false}
                    marginBottom='30px'
                    transform="uppercase"
                    minHeight={{base:'350px', sm:'450px', md:'450px', lg:'550px',xl:'652px'}}
                />
              </GridItem>
          </SimpleGrid>

        </Container>
      </Flex> */}
<Flex flexDirection='column' padding={{sm:'0', md:'50px 0px 60px',lg:'70px 20px 70px',xl:'80px 0px 80px' }} background='#000' marginBottom='100px'>
  <Container maxW='container.xl'>
    <Heading 
      as="h3" 
      title="MORE THAN A SPIN CLASS. AN EXPERIENCE. Fully equipped with Keiser M3I bikes and the best lighting and sound system in Ireland. Our RIDE45, and RHYTHM RIDE classes offer something for everyone."
      variant='medium'
      textAlign="center"
      lineHeight="1"
      width={{lg:'100%', xl:'100%'}}
      padding={{sm:'40px 0px', md:'0px 0px 70px' }}
      className='wow animate fadeInUp'
      color='#fff'
    >
    </Heading>
  </Container>
  <Container maxW='container-fluid'>
      <SimpleGrid 
          columns={{sm:'1',md:'2',lg:'2',xl:'3',xxl:'4'}} 
          columnGap={4} 
          rowGap={2} 
          width="full" 
          marginTop='50px'
          padding={{sm:'0px', md:'0px 30px'}}
        >
            <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
              <ClassCard
                  title='WARM UP'
                  subtitle='Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                  you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                  up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!'
                  bg="url('/images/RhythmRide/WarmUp.webp')"
                  isButton={false}
                  marginBottom='30px'
                  transform="uppercase"
                  minHeight={{sm:'450px', md:'450px', lg:'450px', xl:'500px', xxl:'600px'}}
              />
            </GridItem>
            <GridItem mt={{sm:'40px', xl:'80px'}} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                  title='BEAT OF THE MUSIC'
                  subtitle='TURN OFF THE SCREENS, BRING THE LIGHTS DOWN LOW, AND JUST FEEL THE BEAT OF
                  THE MUSIC. DURING THIS 45 MINUTE FULL BODY EXPERIENCE, YOU WILL HIT CARDIO HIGHS,
                  CHOREOGRAPHY COMBOS AND A DUMBELL DEDICATED TRACK TO REALLY FOCUS ON YOUR UPPER
                  BODY.'
                  bg="url('/images/RhythmRide/Beat.webp')"
                  isButton={false}
                  marginBottom='30px'
                  transform="uppercase"
                  minHeight={{sm:'450px', md:'450px', lg:'450px', xl:'500px',xxl:'600px'}}
              />
            </GridItem>
            <GridItem mt={{sm:'0',lg:'0', xl:'160px'}} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                  title='PERFECTLY CURATED PLAYLIST'
                  subtitle='GET READY TO RIDE TO YOUR INSTRUCTOR’S PERFECTLY CURATED PLAYLIST AND LEAVE THE ROOM
                  LIGHTER AND MORE INSPIRED THAN WHEN YOU WALKED IN.'
                  bg="url('/images/RhythmRide/Playlist.webp')"
                  isButton={false}
                  marginBottom='30px'
                  transform="uppercase"
                  minHeight={{sm:'450px', md:'450px', lg:'450px', xl:'500px', xxl:'600px'}}
              />
            </GridItem>
            <GridItem mt={{sm:'0',md:'40px', lg:'40px', xl:'0', xxl:'240px'}} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                  title='COOL DOWN & HIGH FIVES'
                  subtitle='We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                  to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                  Mojo Health Bar'
                  bg="url('/images/RhythmRide/Cooldown.webp')"
                  isButton={false}
                  marginBottom='30px'
                  transform="uppercase"
                  minHeight={{sm:'450px', md:'450px', lg:'450px', xl:'500px', xxl:'600px'}}
              />
            </GridItem>
        </SimpleGrid>
  </Container>
</Flex>
      
      <Flex flexDirection='column' padding={{sm:'40px 0px 0', md:'40px 0 0' }}>
        <Container maxW='container.xl'>
          <SimpleGrid 
            columns={{sm:'1',md:'2'}} 
            columnGap={10} 
            rowGap={2} 
            width="full" 
            textAlign={{sm: "center", md: "left"}}
          >
              <GridItem>
                <Heading 
                  as="h2" 
                  title="WHO IS RHYTHM RIDE FOR?"
                  variant='extralarge'
                  textAlign={{sm: "center", md: "left"}}
                  lineHeight="1"
                  padding='0px 0px 20px'
                  width={{sm:"",md:"60%",lg:"70%",xl:"80%"}}
                >
                </Heading>
              </GridItem>
              <GridItem >
                <Text 
                  as="p" 
                  fontFamily='poppins' 
                  title=" Anyone and everyone! Especially if, you are a lover of music and working to the beat in choreographed sequences, if you'd like a workout that involves mind-body movement connection and if you like to leave workouts inspired to do and be better!"
                  width='98%'
                ></Text>
              </GridItem>
          </SimpleGrid>

        </Container>
      </Flex>


   
      <Flex flexDirection='column' padding={{ sm:'0 0 20px',md:'50px 0px' ,lg:'70px 0px',xl:'80px 0px'}} position="relative">
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{sm:'97%', md:'90%', lg:'87%', xl:'69%'}} iconUri='/images/comment-quote-outline.svg' isname={false} title='“THE COACHES ARE BOTH PROFESSIONAL AND WELL-TRAINED. I WOULD ABSOLUTELY RECOMMEND PERPETUA TO ANYONE, FROM A BEGINNER TO A EXPERIENCED GYM USER”'/>
        </Container>
      </Flex>
 
      <VideoSection heading='Rhythm Ride' headingSub='at Perpetua' video1='https://youtube.com/embed/Q5v9N-zZckU?si=21Ba9C8bKlzi0zwG' video2='https://youtube.com/embed/1026ZN9O5QE?si=78KiJx16LR1cDuvf' smallHeading='Find out more' smallHeading2='What our clients say' midHeading='' midHeading2=''/>

      {/* <Container maxW='container.xl' padding={{sm:'50px 15px 0',xl:'95px 0px 60px'}} >
        <Flex flexDirection={{sm: "column", md: "row"}} >
          <Box w={{sm:"100%", md: "45%"}} h={{sm:"auto" , md: '40px'}} position={{sm:"relative" , md: 'sticky'}} top={{sm:"0" , md: '100px'}}  paddingBottom={{sm:"30" , md: '10px'}}>
            <Heading title="what does my first class look like? " variant='extralarge' lineHeight='1'></Heading>
          </Box>
          <Box w={{sm:"100%", md: "55%"}} >
              <VStack spacing={2}align='stretch' width={"full"}> */}

                {/* <Stack p='0px 0px 50px' borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='01' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='ARRIVAL AND CHECK IN ' variant='small' margin='0px 0px 30px'></Heading>
                  <Text as='p' title="Please arrive 15 minutes early and check in with our front desk. Our staff I'll introduce you to the coach who is taking the class and they will provide an introduction to how the class works and show you how to use our treadmills."></Text>
                </Stack> */}
                {/* <Stack p='50px 0px' borderBottom='1px solid #D9D9D9'>
                  <Heading as='h2' title='01' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title='WHAT DOES MY FIRST RIDE CLASS LOOK LIKE? ' variant='medium' margin='0px 0px 30px' width='70%' lineHeight='1'></Heading>
                  <br></br> */}
                  {/* <CfProgram 
                    count='[A]' 
                    title='WARM-UP' 
                  >
                  </CfProgram> */}

                  {/* <Text as='p' title="When you arrive for your first class your coach will meet you to take you through your bike set up and brief you for what to expect. During your brief, let your coach know of any injuries etc so they can best 
                  advise you for the class, to make sure you have the most comfortable experience. During your first class, do what feels comfortable for you. Take as many breaks as you need, sit whenever you need and always come back to the legs 
                  if the choreography feels overwhelming. This is a judgement free zone, it's a place you are going to grow and learn, and you will always be supported by your coach and fellow attendees. "></Text>
                 {/*  <br></br> */}
                  {/* <CfProgram 
                    count='[B]' 
                    countColor= "#000"
                    title='THE WORKOUT' 
                  >
                  </CfProgram>
                  <br></br>
                  <Heading as='h3' title='TREADMILL' variant='small' margin='0px 0px 30px'lineHeight='1'></Heading>

                  <Text as='p' title='Lace up your trainers and get ready to work. Your coach will guide you through your running 
                  Rounds on our state-of-the-art treadmills, cueing speed and incline intervals. Not a runner? No problem. Your coach 
                  Will give you a range of beginner, intermediate, and advanced speeds.'></Text>

                  <br></br>
                  <Heading as='h3' title='FLOOR WORK' variant='small' margin='0px 0px 30px'lineHeight='1'></Heading>
                  <Text as='p' title='Let’s get spicy. Our floor rounds incorporate a variety of movements and equipment, including boxes, trx ropes, 
                  and our signature torpedoes. Floor rounds are designed to maximise your strength and agility, complimenting the work you’re doing on the tread.'></Text> 
                </Stack>
                <Stack p='50px 0px'>
                  <Heading as='h2' title='02' color='Red' lineHeight='1'></Heading>
                  <Heading as='h3' title="HIGH FIVES & DE-BRIEFS" variant='small' margin='0px 0px 30px'></Heading>
                  <Text as='p' title="We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back to normal and stretch out your muscles."></Text>
                </Stack>
              </VStack>
          </Box>
        </Flex>
      </Container> */}

     

      <Flex 
        flexDirection={{sm: "column-reverse", md: "row"}}
        padding={{sm:'50px 0px ', md:'30px 0px 0' }} 
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Flex width={{sm: "100%", md:"50%"}} backgroundColor='LightGrey' padding={{sm: "35px 0 42px", md: "50px 0"}} position="relative" justifyContent="flex-end"
        _after={{position:'absolute',content:'""',width:'200px', height:'100%', top:'0',left:'100%',background:'LightGrey',zIndex:'-1', display:{sm:"none", md:"block"}}}
        
        > <Box width={{md: "100%", xl:"630px"}}  padding="0 15px">
            <Heading title='EVERY CLASS IS COACH-LED BY PROS' variant="extralarge" lineHeight='1' margin={{sm:'0px 0px 30px', md:'0px 0px 60px'}}></Heading>
              <Text 
                title="These aren’t your run of the mill spinning coaches. These are professional coaches with years of experience and dedicated to helping you get results."
                margin='0px 0px 20px'
              >      
              </Text>
              <Text title="When you join a class at PERPETUA, you’re not just a number. Here, you are paying for guidance, accountability, and motivation from professionals who know how to get it done."></Text>
          </Box>
        </Flex>
        <Box 
        width={{sm: "100%", md:"50%"}}
        backgroundImage='url(./images/every_class.webp)'
        height={{sm: "300", md: '558px'}}
        backgroundRepeat='no-repeat'
        backgroundPosition='center'
        backgroundSize='cover'
        ></Box>

      </Flex>

      {/* <Flex 
        flexDirection={{sm: 'column', md: "row"}}
        padding={{sm:'30px 0px 35px ', md:'250px 0px 0px' }}
        alignItems='center'
        alignContent='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Box width={{sm: '100%', md: "50%"}} textAlign='right'>
            <Box 
            backgroundImage='url(./images/sweat-bg2.png)' 
            width={{sm:"100%" , md: '551px'}} height={{sm: "350px", md: '671'}} 
            display={{sm:"block", md: 'inline-block' }}
            backgroundRepeat='no-repeat'
            backgroundPosition='top'
            backgroundSize='cover'
            position='relative'
            zIndex='1'
            top={{sm: "0", md: "-80px"}}
            >
            </Box>
        </Box>
        <Box width={{sm: '100%', md: "58%"}} backgroundColor='LightGrey' marginLeft={{sm: "0", md: '-145px'}} padding={{sm: "30px 20px 30px", md: "110px 100px 150px 190px", xl:  '110px 100px 140px 190px'}}   >
            <Heading title="PERPETUA FITNESS IS THE HOME OF THE BEST FIRST EXPERIENCE IN IRELAND" variant="extralarge" lineHeight='1' margin={{sm:'0px 0px 30px', md:'0px 0px 60px'}}></Heading>
            <Text title="You can expect the best experience from the moment you walk in the doors of Perpetua. All of our classes are designed in a way to make training enjoyable and get the results you’ve always wanted. Perpetua is a home for everyone."
              margin='0px 0px 20px' />
            
        </Box>

      </Flex> */}

      <Flex 
        flexDirection='column' 
        padding={{sm:'20px 0px 50px', md:'100px 0px 100px' }} 
        textAlign='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Container maxW='container.xl'>
            <Box>
                <Heading 
                as="h2" 
                title="ARE YOU READY?"
                variant='extralarge'
                textAlign="center"
                lineHeight="1"
                padding={{base:'0px 0px 15px', sm:'0px 0px 30px' }}
              >
              </Heading>
              <Text 
                title="Try 3 classes for 33 euros. All fitness levels welcome "
                as='p'
              >
              </Text>
              
              <Link href='/contact-us' _hover={{textDecoration:'none'}}><Button title='Get Started' className="wow fadeInUp" color='black' margin='40px 0px 0px '/> </Link>
              <Box >
              <Heading 
                as="h5" 
                title="Download our app"
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{base:'15px 0px 0px', sm:'30px 0px 0px' }}
                display='inline-block'
              >
                
              </Heading>

              <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                <Heading 
                as="h5" 
                title="Here"
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{base:'15px 0px 0px', sm:'30px 0px 0px' }}
                display='inline-block'
                margin='0px 0px 0px 5px'
                color='Red'
                textDecoration='underline'
              ></Heading>
              </Link>
              </Box>
            </Box>
        </Container>
      </Flex>

  


      <Box padding={{sm:'35px 0px 30px', md:'80px 0px' }} background={"#eeeef1"}>
        <Container maxW='container.xl' >
          <Flex  margin="auto" textAlign={'center'} width={{sm:'100%', md:'50%' }} flexDirection="column" paddingBottom="30px">
            <Heading 
              lineHeight={"1"} 
              variant="large" 
              title="Frequently Asked Questions" 
              marginBottom='30px' 
            />
            <Box mb="40px">
                <Text 
                    title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
                /> 
            </Box>
          </Flex>
          
          <Flex flexDirection={{sm: "column", md:"row"}}>

            <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
              <Heading 
                lineHeight={"1"} 
                variant="smallmid" 
                title="BEFORE CLASS" 
                marginBottom='20px' 
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}> 
              <Heading 
                lineHeight={"1"} 
                variant="smallmid" 
                title="DURING CLASS" 
                marginBottom='20px' 
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box width={{sm:'100%', md:'33.33%' }} paddingBottom={{sm: "30px", md: 0}}>
              <Heading 
                lineHeight={"1"} 
                variant="smallmid" 
                title="AFTER CLASS" 
                marginBottom='20px' 
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>
            

          </Flex>

          


            
        </Container>
      </Box>

      <BlogSection catSlug = 'ride' />
      
      <AppDownload 
            bg="url('/images/App-section-bg.webp')" 
            heading='Your Daily Dose of Online Workouts Is Live'
            para='The Best Online Training Platform'
            imageUri='/images/iphone.webp'
            appstore='/images/app-store.png'
            googleplay='/images/google-play.png'
            className="_appdownload_section"
            googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
            appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
            isAppButton={false}
            link="/online-gym-programming"
            alt="iphone - spin class dublin"
      />


    </>
  );
}

export default RidePage;
