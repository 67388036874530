import React, { useEffect } from 'react';
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import * as Style from './Corporate.style';
import { Container, SimpleGrid, VStack, Box,} from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import CorporateCard from '../component/CorporateCard/CorporateCard';
import CorporateContent from '../component/CorporateCard/CorporateContent';
import CorporateimageBox from '../component/CorporateCard/CorporateimageBox';
import CorporateimageCard from '../component/CorporateCard/CorporateimageCard';
import PrivateHireAndCorporateForm from '../component/Form/PrivateHireAndCorporateForm';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoCard from '../component/VideoCard/VideoCard';
import { Helmet } from 'react-helmet';

import VideoBanner from '../component/VideoBanner/VideoBanner';

function Corporate() {


  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  let CorporateimageBoxcontent = [
    {
      Heading: 'PRIVATE CLASSES',
      paragraph: 'Fancy your own private class ? Host your own group or corporate class in our RIDE or SWEAT studios. You can hire our studios to schedule a weekly or monthly private class for your employees. The Perpetua team can assist with any special requirements and will provide any of our in house instructors upon request',
      list: [
        // "Bundles available starting at 50 credits",
        // "Our team will provide usage reports and insights on a weekly, monthly, or quarterly basis"
        "Step outside the office and take your pick between Tread & Shred, RIDE or Rumble.",
        "Exclusive add ons incl. shakes and water available to enhance your event. Special in house lighting, visuals and music playlists available upon request"
      ]

    }
  ]

  let CorporateCardcontent = [
    {
      Heading: 'SHARED CREDIT POOL',
      paragraph: 'An easy, low-lift way to invest in your people! Set up a shared credit pool that your employees can use to book into our studio classes. This offers your team members flexibility and autonomy and is our most popular corporate perk by far!',
      list: [
        "Exclusive add ons available to create your desired event",
        "Special in house lighting, visuals and music playlists available upon request"
      ]
    },
  ]

  let CorporateimageCardcontent = [
    {
      Heading: 'PERPETUA ANYWHERE',
      paragraph: 'Is your team spread out across the world working remotely? Keep everyone moving with Perpetua Anywhere, Ireland’s number 1 health and fitness app. Perpetua Anywhere offers a wide range of classes including HIIT workouts, mindful meditation, RIDE classes, and more.',

    },
    {
      Heading: 'EMPLOYEE GIFTING ',
      paragraph: 'Celebrate work anniversaries or a job well-done by giving the gift of fitness! We offer corporate gift vouchers as well as bespoke gift packages to include in your employee recognition programs.',
    },
  ]

  return (
    <>
      <Helmet
        script={[
          {
            innerHTML: `window.lintrk('track', {
              conversion_id: 15193380
            });`,
          },
        ]}
      />
      
      <MetaTitle title='Corporate Wellness & Fitness - Dublin | Perpetua Fitness' />
      {/* <InnerBanner
        backgroundColor='black'
        backgroundImage={{ sm: 'url(./images/corporate_m.jpg)', md: 'url(./images/corporeate_main_image.jpg)' }}
        title='TRAIN WITH YOUR TEAM'
        subtextpara="INVITE YOUR WORK FRIENDS TO PERPETUA TO MOVE AND CONNECT TOGETHER!"
        issubtextpara={true}
        isButton={false}
        isButton2={true}
        issubtitleRight={false}
        isSubtext={false}
        buttontitle2="Book A Consult"
        width={{ sm: '100%', md: '100%' }}
        height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
        margin="30px 0 0"
        Link2="/join-today"

      ></InnerBanner> */}

      <VideoBanner 
        videobanner='../video/video-corporat.mp4'
        title="TRAIN WITH YOUR TEAM"
        isSubtext={false}  
        isButton={false} 
        issubtextpara={true} 
        subtextpara='WORK FRIENDS TO PERPETUA TO MOVE AND CONNECT TOGETHER!'
        isButton2={true}
        buttontitle2="Book A Consult"
        margin="0px 0 0"
        videoModal='https://www.youtube.com/embed/t3x47LUlTog' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={false} 
        marginTop='20px 0px 0px'
      />
      <Style.CorporateOffering>
        <Container maxW='container.xl'>
          <Text title='PERPETUA @ WORK' as='small' color='#fff'></Text>
          <Heading title='CORPORATE WELLNESS' as='h2' color='#fff' margin='0px 0px 25px' className='wow animate fadeInUp'></Heading>
          <Text title='Offer your employees and clients a premium fitness experience at Perpetua! We can create a specially-curated package for your company to support your team members both physically and mentally. Let’s work together to create a full-circle employee wellness experience!' as='p' color='#fff' width={{ sm: '100%', md: '80%' }} className='wow animate fadeInUp' margin='0px auto'></Text>
        </Container>
      </Style.CorporateOffering>

      <CorporateCard bg="url('/images/corpotare1.jpg')">
        <CorporateContent width={{ md: '55%', lg: '60%', xl: '50%' }} Heading={CorporateCardcontent[0].Heading} paragraph={CorporateCardcontent[0].paragraph} list={CorporateCardcontent[0].list} btnColor='#000'></CorporateContent>
      </CorporateCard>

      <CorporateimageBox bg="url('/images/corporate-bg.jpg')">
        <CorporateContent width={{ md: '55%', lg: '60%', xl: '50%' }} Heading={CorporateimageBoxcontent[0].Heading} paragraph={CorporateimageBoxcontent[0].paragraph} list={CorporateimageBoxcontent[0].list} color='#fff'></CorporateContent>
      </CorporateimageBox>

      <Container maxW='container.xl' padding={{ sm: '50px 20px', md: '50px 20px', lg: '50px 35px', xl: '100px 20px' }}>
        <SimpleGrid columns={{ sm: '1', md: '2' }} spacing={10}>
          <CorporateimageCard Heading={CorporateimageCardcontent[0].Heading} paragraph={CorporateimageCardcontent[0].paragraph} btnColor='#000'>
            <Style.Corporateimage backgroundImage="url('/images/corporate4.jpg')" ></Style.Corporateimage>
          </CorporateimageCard>

          <CorporateimageCard Heading={CorporateimageCardcontent[1].Heading} paragraph={CorporateimageCardcontent[1].paragraph} btnColor='#000'>
            <Style.Corporateimage backgroundImage="url('/images/corporate5.jpg')" ></Style.Corporateimage>
          </CorporateimageCard>

        </SimpleGrid>
      </Container>
      <Style.VideoSection>
        <Container maxW='container.xl'>
          <Style.VideoWrapper>
            <VideoCard src='https://www.youtube.com/embed/6AfQQs0neAU?si=8nefcVpsH9ZAlfrZ' width={{ sm: '100%', md: '800px' }} maxHeight={{ sm: '420px', md: '600px' }}></VideoCard>
          </Style.VideoWrapper>
        </Container>
      </Style.VideoSection>

      <VStack backgroundColor='black' padding={{ sm: '50px 0px', md: '96px 0px' }} borderBottom='1px solid #232323'>
        <Container
          display='flex'
          maxW='container.xl'
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{ sm: '100%', md: '50%', lg: '40%' }} textAlign={{ sm: 'center', md: 'left' }} marginBottom={{ base: '50px', lg: '0' }} position={{ sm: 'relative', md: 'sticky' }} top={{ sm: '0px', md: '100px' }} height={{ sm: 'auto', md: '500px' }}>
            <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
            <Heading title='The sky is the limit! We can create a bespoke package that suits the needs of your team. We are here to help – so get in touch and let’s get moving!' variant='smallheading' lineHeight='1.4' margin='0px 0px 30px' color='white'></Heading>
          </Box>
          <Box w={{ sm: '100%', md: '50%', lg: '60%' }}>
            <PrivateHireAndCorporateForm />
          </Box>
        </Container>
      </VStack>

    </>
  );
}

export default Corporate;