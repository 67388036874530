import React from 'react';
import * as Style from './CorporateCard.style';
import {Container} from '@chakra-ui/react';
const CorporateCard  = ( props ) => {
  return (
  <> 
    <Container maxW='container.xl'>
        <Style.CorporateCard>
            <Style.CorporateCardImage backgroundImage={props.bg}></Style.CorporateCardImage>
            {props.children}
        </Style.CorporateCard>
    </Container>
  </>
  );
}
export default CorporateCard ;

