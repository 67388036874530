import React from 'react'
import * as Style from './GuestListing.style';
import {Container,Link} from '@chakra-ui/react';

const TwoclumnContent = (props) => {
  return (
    <Style.midContent>
      <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
        <Style.midcontentWrapper>
          <Style.imageBox>
            <Style.image alt='image' src={props.imageUri}/>
          </Style.imageBox>
          <Style.contentBox>
          {props.heading && <Style.h2 variant='small' title={props.heading} marginBottom='20px'/>}
            {props.subHeading && <Style.description title={props.subHeading} fontSize={{lg:'16px', xl:'18px'}}/>}

            <Style.pointList>
              {/* {
                props.list.map((item,index)=>{
                    return(
                      <Style.pointlistItem>
                        {item.title}
                        {item.linkTitle && <Style.link href={item.linkPage} >{item.linkTitle}</Style.link>}
                      </Style.pointlistItem>
                    )
                })
              } */}
              <Style.pointlistItem>Invite a newbie to Perpetua</Style.pointlistItem>
              <Style.pointlistItem>Get them create a Perpetua account <Link href="/sign-up" textDecoration='underline'>here</Link> & purchase our Intro Offer 3 for €33 <Link href="/pricing" textDecoration='underline'>here</Link> </Style.pointlistItem>
              <Style.pointlistItem>Book yourselves into the same class </Style.pointlistItem>
              <Style.pointlistItem>Check in together at Front of House to avail of the promotion </Style.pointlistItem>
              <Style.pointlistItem>Check your account to find your studio class credit that you earned</Style.pointlistItem>
            </Style.pointList>

          </Style.contentBox>
        </Style.midcontentWrapper>
      </Container>
    </Style.midContent>
  )
}

export default TwoclumnContent