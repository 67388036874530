import React from "react";
import {Box, Heading} from '@chakra-ui/react';
import './StudioStyle.css';
function StudioCard(props){

    return(
        <Box maxW={{xl:'full'}}
        className="sBox"
        backgroundImage={props.bg}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={props.height}
        backgroundSize={"cover"}
        position="relative"
        
        >
            <Box className="sBoxInner" backgroundColor='rgba(0,0,0, 0.4)' textTransform={props.textTransform} visibility={{sm:'visible', md:'hidden'}} display='flex' justifyContent='center'  alignItems='center' height="100%" w="100%"
            >
                <Heading fontSize='40px' color='white'>{props.title}</Heading>
            </Box>   
        </Box>
    )

}
export default StudioCard;