
const size = {
    mobile: '320px',
    mobileM: '375px',
    mobileL: '428px',
    mobileS: '480px',
    tabletS: '767px',
    tablet: '768px',
    tabletM: '992px',
    tabletL: '1024px',
    tabletLM: '1199px',
    desktop: '1280px',
    desktopM: '1365px',
    desktopL: '1440px'
  }
 
  export const device = {
    mobile: `(min-width: ${size.mobile})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    mobileS: `(max-width: ${size.mobileS})`,
    tabletS: `(max-width: ${size.tabletS})`,
    tablet: `(min-width: ${size.tablet})`,
    tabletM: `(min-width: ${size.tabletM})`,
    tabletL: `(min-width: ${size.tabletL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopM: `(min-width: ${size.desktopM})`,
    tabletLM: `(max-width: ${size.tabletLM})`,
  };
 