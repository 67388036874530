import { Box } from '@chakra-ui/react';
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const PodcastEpisodeInfo = styled(Box)(props =>({
    backgroundColor: 'lightGrey',
    padding: '40px 0px 50px',
    
    [`@media ${device.tablet}`]: {
        padding: '80px 0px'
    },
}))
export const PodcastEpisodeView = styled(Box)(props =>({
    margin: '0px auto',
    textAlign: 'center',
    paddingTop: '40px'
}))