import { Box, Stack, position } from '@chakra-ui/react';
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const PodcastImage = styled(Box)(props =>({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSiz: "cover",
    width: "100%",
    backgroundSize: "cover",
    position: "relative", 
    ":after":{
        background:'rgba(0,0,0,0.4)',
        position:'absolute',
        left:'0',
        top:'0',
        width:'100%',
        height:'100%',
        content:"''",

    }
}))
export const PodcastImageInfo = styled(Box)(props =>({
    paddingTop: '30px',
    paddingLeft:'15px',

    [`@media ${device.tablet}`]: {
        paddingTop: '15px',
        paddingLeft:'30px',
    },
}))

export const PodcastImageContent = styled(Stack)(props =>({
    spacing: "1",
    align: "left",
    bottom: "0",
    width: "full",
    position: "absolute",
    left: "0",
    paddingLeft: "15px",
    paddingBottom: "20px",
    zIndex:'1',
    [`@media ${device.tablet}`]: {
        paddingLeft: "30px",
        paddingBottom: "25px",
        
    },
   
}))

export const PodcastContent = styled(Box)(props =>({
    paddingLeft: '10px',
    position: 'relative',
    ":after": {
        position: 'absolute',
        content: '""',
        width: '2px', 
        height: '65%',
        top: '3px',
        left: '0',
        background: '#D12C41'
    }

}))