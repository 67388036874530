import React from 'react';
import {Stack, VStack} from '@chakra-ui/react';
import Heading from '../Heading/Heading';

function CfProgram(props) {
  return (


        <VStack spacing={2}align='stretch' width={"full"}>
            <Stack direction={['row']} spacing='10px'>
                {props.count && <Heading title={props.count} variant="medium" color={props.countColor}></Heading>}
                <Heading title={props.title} variant="medium" color='Red'></Heading>
            </Stack>
        </VStack>


  )
}

export default CfProgram;
