import { Box, Flex,  ListItem } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";

export const Page404Info = styled(Box)(props =>({ 
    padding: "40px 0px",
   
    [`@media ${device.tablet}`]: {
        
        padding: "80px 0px",
    },
}))

export const Page404iPhoneInfo = styled(Box)(props =>({ 
    backgroundColor: 'black',
    padding:'25px 0px'
    
}))

export const Page404OnlineInfo = styled(Box)(props =>({ 
    backgroundColor: 'lightGrey',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '40px 10px 50px',
    
    [`@media ${device.tablet}`]: {
        padding: "50px 10px 50px",  
    }, 
     

    [`@media ${device.desktop}`]: {  
        padding: "40px 10px 0px",   
    },
}))

export const Page404ShopInfo = styled(Flex)(props =>({ 
    position: 'relative',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "50px 0px",
    minHeight: '450px',

    [`@media ${device.tablet}`]: {
        padding: "60px 0px",
        minHeight: '500px'
    },
    [`@media ${device.tabletL}`]: {
        minHeight: '550px',
    },
    [`@media ${device.desktop}`]: {  
        minHeight: '630px',
    },
}))
export const Page404ShopContent = styled(Box)(props =>({ 
    alignItems: 'flex-end',
    textAlign: 'left',
    paddingTop: '50px',
    paddingBottom: '50px',
    display: 'flex',
    paddingLeft: '0px',
    [`@media ${device.tablet}`]: {
       alignItems: 'center',
       paddingTop: '0px',
       paddingBottom: '0px',
       paddingLeft: '50px',
    },
   
    [`@media ${device.desktop}`]: {  
        paddingLeft: '305px',
    },
}))
   
export const Page404Shop = styled(Box)(props =>({ 
    width: '100%',

    [`@media ${device.tabletL}`]: {
        width: '95%',
    },
    [`@media ${device.desktop}`]: {  
        width: '80%',
    },
}))

