import React, {lazy, Suspense, useState} from "react";
import { FormControl, Stack, Box, Input, Select, Textarea, Flex } from '@chakra-ui/react';
import Button from '../Button/CustomeButton';
import axios from 'axios';
import Text from "../SubHeading/SubHeading";
const ReCAPTCHA = lazy(()=>import('react-google-recaptcha')) ;


function PrivateHireAndCorporateForm() {
    const [successFrom, setsuccessFrom] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);

    const [isCaptcha, setIsCaptcha] = useState(null);
    const [errorCaptcha, setErrorCaptcha] = useState('');

    const getReCaptchaVal = (value) => {
        setIsCaptcha(value);
        setErrorCaptcha('');
    }

    const [contactForm, setContactForm] = useState({
        name: "",
        phone: "",
        email: "",
        company: "",
        employees: "",
        TypeOfEnquiry: "",
        PreferredStudio: "",
        message: "",

    });
    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setContactForm({ ...contactForm, [name]: value })
    }
    const handelSubmit = (e) => {
        e.preventDefault();
        setsuccessFrom('');
        setBtnDisable(true);

        if (isCaptcha === null) {
            setBtnDisable(false);
            setsuccessFrom('');
            setErrorCaptcha('Please verify that you are not a robot.');
            return false;
        }

        axios.post(process.env.REACT_APP_ADMIN_API_BASE_URL + '/privatehireandcorporate', {
            name: contactForm.name,
            phone_no: contactForm.phone,
            email: contactForm.email,
            company: contactForm.company,
            number_of_employees: contactForm.employees,
            type_of_enquiry: contactForm.TypeOfEnquiry,
            preferred_studio: contactForm.PreferredStudio,
            message: contactForm.message,

        })
            .then((response) => {
                setBtnDisable(false);
                console.log(response.data.success);
                setsuccessFrom(response.data.success);
                window.grecaptcha.reset();
                contactForm.name = "";
                contactForm.phone = "";
                contactForm.email = "";
                contactForm.company = "";
                contactForm.employees = "";
                contactForm.TypeOfEnquiry = "";
                contactForm.PreferredStudio = "";
                contactForm.message = "";
            })
            .catch((error) => {
                setBtnDisable(false);
                setsuccessFrom('');
                window.grecaptcha.reset();
            });
    }

    return (
        <>
            <form onSubmit={handelSubmit} className="privateHireCorporate" id="privateHireCorporate">

                <FormControl isRequired width={{ sm: '100%', md: '90%' }} p={{ sm: '20px', md: '30px 20px', lg: '50px' }} m='0px auto' border='2px solid' borderColor='Red' borderRadius='20px'>

                    <Stack direction={['column']} spacing='24px' position='relative'>
                        <Input placeholder="Name *" _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="name" value={contactForm.name} onChange={handelInput} />
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Input placeholder="PHONE NUMBER *" type='number' _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="phone" value={contactForm.phone} onChange={handelInput} />
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Input placeholder="Email *" type='email' _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="email" value={contactForm.email} onChange={handelInput} />
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Input placeholder="COMPANY NAME *" type='text' _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="company" value={contactForm.company} onChange={handelInput} />
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Input placeholder="Number Of Employees *" type='number' _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0 !important' }} border='0' borderBottom='2px solid #252525' height='50px' borderRadius='0px' color='white' fontSize='xs' name="employees" value={contactForm.employees} onChange={handelInput} />
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Select placeholder=" Type Of Enquiry *" _placeholder={{ color: "black", fontSize: '16px' }} borderRadius='0' height='50px' textColor='white' fontSize='xs' textTransform='uppercase' name="TypeOfEnquiry" border='0' borderBottom='2px solid #252525' value={contactForm.TypeOfEnquiry} onChange={handelInput}>
                            <option style={{ color: 'black' }} value='Corporate Memberships'>Corporate Memberships</option>
                            <option style={{ color: 'black' }} value='Group Booking'>Group Booking</option>
                            <option style={{ color: 'black' }} value='Studio Hire'>Studio Hire</option>
                            <option style={{ color: 'black' }} value='Other'>Other</option>
                        </Select>
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Select placeholder=" What Is Your Preferred Studio *" _placeholder={{ color: "black", fontSize: '16px' }} borderRadius='0' height='50px' textColor='white' textTransform='uppercase' name="PreferredStudio" border='0' fontSize='xs' borderBottom='2px solid #252525' value={contactForm.PreferredStudio} onChange={handelInput} >
                            {/* <option style={{ color: 'black' }} value='St Mary Axe'>St Mary Axe</option>
                            <option style={{ color: 'black' }} value='Broadgate'>Broadgate</option>
                            <option style={{ color: 'black' }} value='Victoria'>Victoria</option>
                            <option style={{ color: 'black' }} value='Angel'>Angel</option>
                            <option style={{ color: 'black' }} value='Holborn'>Holborn</option>
                            <option style={{ color: 'black' }} value='Oxford Circus'>Oxford Circus</option>
                            <option style={{ color: 'black' }} value='Bayswater'>Bayswater</option>
                            <option style={{ color: 'black' }} value='Hammersmith'>Hammersmith</option>
                            <option style={{ color: 'black' }} value='Holborn'>South Bank</option>
                            <option style={{ color: 'black' }} value='High Street Kensington'>High Street Kensington</option>
                            <option style={{ color: 'black' }} value="St John's Wood">St John's Wood</option> */}
                            <option style={{ color: 'black' }} value="Windmill lane Studio">Windmill lane Studio</option>
                            <option style={{ color: 'black' }} value="Lennox Street Studio">Lennox Street Studio</option>
                        </Select>
                    </Stack>
                    <Stack direction={['column']} spacing='24px' position='relative' marginTop='25px'>
                        <Textarea placeholder="Message *" _placeholder={{ color: 'white', fontFamily: 'BlenderProBold , sans-serif', fontSize: '16px', textTransform: 'uppercase', padding: '0px !important' }} border='0' borderBottom='2px solid #252525' height='100px' borderRadius='0px' color='white' fontSize='xs' name="message" value={contactForm.message} onChange={handelInput} ></Textarea>
                    </Stack>
                    <Stack direction={{ sm: 'column', xl: 'row' }} spacing='0px' position='relative' marginTop='25px'>
                        <Suspense fallback={"Loading.."}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY} onChange={getReCaptchaVal} className='captcha' theme="dark" />
                        </Suspense>
                        <Box marginLeft={{ xl: '-10px !important' }} marginTop={{ xl: '5px !important' }} >
                            <Button btnDisable={btnDisable} submit="submit" title='Contact' width='208px' color='white'></Button>
                        </Box>
                    </Stack>
                    {errorCaptcha &&  <Text title={errorCaptcha} fontSize="14px" color="red"/>}
                    <Flex justifyContent="center" margin="30px 0 0"> {successFrom && <Text title={successFrom} fontSize="16px" color="green" />}</Flex>

                </FormControl>
            </form>
        </>
    )
}

export default PrivateHireAndCorporateForm;
