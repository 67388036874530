import React from 'react';
import * as Style from '../Location.style';
import ContentBox from './ContentBox';
import Heading from '../../Heading/Heading';
import {  Container,} from '@chakra-ui/react';
const ContentboxCard = (props) => {
  return (
    
        <Style.ClassSection>
            <Container maxW='container.xl'>
            <Heading title="Our Classes" variant="extrasmall" margin="0px" color='#fff'/>
            <Style.ClassWrapper spacing={{md:'10px',lg:'24px'}}>
                {props.classbox.map((item,index)=>{
                    return (
                        <Style.CBox key={index}>
                            <ContentBox
                                Background={item.image}
                                BoxHeading={item.heading}
                                BoxDescription={item.description}
                                Link={item.link}
                            />
                        </Style.CBox>
                    )
                })}
            </Style.ClassWrapper>
            </Container>
        </Style.ClassSection>

  )
}

export default ContentboxCard