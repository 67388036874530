import React, {useEffect} from 'react';
import {Box, Stack, Image, Container, Link} from '@chakra-ui/react';
import StudioCard from '../component/Studios/StudioCard';
import * as Style from './LinkUnderline.style';
import StudioPricingWraper from '../component/Pricing/StudioPricingWaper';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';

function Studios(){

    useEffect(() => {
        new WOW.WOW({
          live: true,
          offset: 0,
          mobile: true,
        }).init();
      }, [])

    return(
        <>
            <MetaTitle title='Studios | Perpetua Fitness'/>
            <Box p={{sm:'50px 0px', md:'100px 0px', xl:'100px 70px'}} >
                <Container maxW='container.xl' >   
                   <StudioPricingWraper />
                </Container> 
            </Box>
        
            {/* <Stack direction={{sm:"column", md:"row"}} padding={{sm:'60px 0px 0px', md:'0px'}} backgroundColor='black' height='300px' spacing={{sm:'40px',lg:'60px'}} alignItems='center' justifyContent={{ md:"center"}} >
                <Box display='inline-block' padding='6px' border='1px solid white'><Link href='https://apps.apple.com/ie/app/perpetua-fitness/id1444090131' isExternal> <Image border='1px solid white' alt='image' src='/images/appleStorelogo.png'/></Link></Box>
                <Box display='inline-block' padding='6px' border='1px solid white'><Link href='https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness' isExternal> <Image border='1px solid white' alt='image' src='/images/googlePlaylogo.png'/></Link></Box>
            </Stack> */}

            <Box backgroundColor='black'>
                <Stack direction={{sm:"column", md:"row"}} padding={{sm:'50px 0px', md:'0px' }} spacing='0px' >
                    <Box w={{sm:"100%", md:"50%"}}>
                        <Style.ButtonLinkUnderline href="/ride" >
                            <StudioCard 
                                bg="url('/images/Ride-Studio.jpg')"
                                title="Ride"
                                textTransform='uppercase'
                                height={{ sm:'400px', md:'450px', lg:'550px',xl:'550px'}}
                                
                            />
                        </Style.ButtonLinkUnderline>
                    </Box>
                    <Box w={{sm:"100%", md:"50%"}}>
                        <Style.ButtonLinkUnderline href="/treadandshred">
                            <StudioCard 
                                bg="url('/images/Sweat-studio.jpg')"
                                title="Sweat"
                                textTransform='uppercase'
                                height={{ sm:'400px', md:'450px', lg:'550px',xl:'550px'}}
                            />
                        </Style.ButtonLinkUnderline>
                    </Box>
                    
                </Stack>
                
                {/* <SimpleGrid columns={{sm:'1',md:'2'}} padding="0px 30px" spacing={6}>
                    <Box  width='100%' height='50px'  border='2px solid grey' color='grey'
                        display='flex' justifyContent='center' alignItems='center' _hover={{bg:'grey', color:'white'}} borderRadius='5px'>
                        <Link> BUY STUDIO CREDITS</Link> 
                    </Box>
                    
                    <Box  width='100%'  height='50px' border='2px solid grey' display='flex' color='grey'
                        justifyContent='center' alignItems='center' _hover={{bg:'grey', color:'white'}} borderRadius='5px'>
                        <Link> MEMBERSHIPS</Link>
                    </Box>
                
                </SimpleGrid> */}
            </Box> 
           
        </>
    )
}

export default Studios;