import React from 'react';
import {
    Box, 
    Container,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from '@chakra-ui/react';
import {MinusIcon, AddIcon } from '@chakra-ui/icons'


import Text from'../SubHeading/SubHeading';

import './Accordian.css'

/* const accoData = [
    {
        "id": 1,
        "title": "Accordian title",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
        "id": 2,
        "title": "Accordian title 2",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
        "id": 3,
        "title": "Accordian title 3",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    }
] */




function Accordian(props) {
  return (
  
    <Container maxW='container.xl'>
        <Accordion allowToggle>

            {props.data.map((item, index) => {
                return (
                    <AccordionItem key={index}>
                    {({ isExpanded }) => (
                        <>
                            
                            <AccordionButton paddingLeft="0" paddingRight="0">
                                <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                                    
                                {item.title} 
                                </Box>
                                {isExpanded ? (
                                <MinusIcon fontSize={{sm:"10px", md:"12px"}} />
                                ) : (
                                <AddIcon fontSize={{sm:"10px", md:"12px"}} />
                                )}
                            </AccordionButton>
                            
                            <AccordionPanel pb={4}>
                                <Text fontSize={{sm:'14px', md:'16px'}} title={item.description} />
                            </AccordionPanel>
                        </>
                        )}
                    </AccordionItem>

            )})}

        </Accordion>
    </Container>

  )
}
export default  Accordian;
