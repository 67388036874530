import React, {useEffect} from "react";
import InnerBanner from '../component/InnerBanner/InnerBanner';
import * as Style from './Iconic.style';
import Heading from "../component/Heading/Heading";
import Text from "../component/SubHeading/SubHeading";
import { Container,Flex,Box,Stack,VStack, Link,} from "@chakra-ui/react";
import Button from '../component/Button/CustomeButton';
import MetaTitle from "./MetaTitle";
import WOW from 'wowjs';

const Iconic = (props) => {
    
    useEffect(() => {
        new WOW.WOW({
          live: true,
          offset: 0,
          mobile: true,
        }).init();
      }, [])

    return (
        <> 
         <MetaTitle title='Iconic | Perpetua Fitness' />
        <Style.IconicWraper>

            <InnerBanner
            backgroundColor='black'
            backgroundImage='url(./images/Crossfit-banner.jpg)'
            subtitle='Perpetua'
            title='ICONIC OFFICES'
            issubtextpara={false}
            isButton={false}
            buttontitle='ENQUIRE NOW'
            issubtitleRight={false}
            isSubtext={true}
            width={{ sm: "100%" }}
            height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
            Link='/contact-us'
            margin='10px 0px 0px'
            ></InnerBanner>
            <Style.InnovationPassionWrapper>
                <Container maxW={{ md:'container.lg',xl:'container.xl'}}>
                    <Style.InnovationPassion>
                        <Heading title='INNOVATION & PASSION THROUGH MOVEMENT' color='black' padding={{sm:'0 0 10px', md:'0'}} />
                        <Text title="Our passion at Perpetua is to create a safe and effective training environment with a supportive community of like-minded individuals. Our coaches are full-time professionals who have dedicated their lives to helping others through health and fitness. We are delighted to collaborate with ICONIC and provide a full-circle wellness experience to ICONIC community members across Dublin through movement, education, and community."
                        color='black' width={{md:'85%'}} margin='0 auto'/>
                    </Style.InnovationPassion>
                </Container>
            </Style.InnovationPassionWrapper>

            <Style.IconicOfferWraper>
                <Container maxW={{ md:'container.lg',xl:'container.xl'}}>
                    <Flex flexDirection={{sm: "column", md: "row"}}>
                    <Box w={{sm:"100%", md: "45%"}} h={{sm:"auto" , md: '40px'}} position={{sm:"relative" , md: 'sticky'}} top={{sm:"0" , md: '100px'}}  paddingBottom={{sm:"30" , md: '10px'}}>
                        <Heading title='EXCLUSIVE ICONIC OFFERS' variant='extralarge' lineHeight='1'></Heading>
                        <Text as='p' title='As an ICONIC member, you can avail of special Perpetua offers:'></Text>
                    </Box>
                    <Box w={{sm:"100%", md: "55%"}} >
                        <VStack spacing={2}align='stretch' width={"full"}>
                            <Stack p='0px 0px 15px' borderBottom='1px solid #D9D9D9'>
                                <Heading as='h2' title='01' color='Red' lineHeight='1'></Heading>
                                <Heading as='h3' title='10% off 10 & 20 studio credit packs' variant='small' margin='0px 0px 30px'></Heading>
                            </Stack>
                            <Stack p='15px 0px'  borderBottom='1px solid #D9D9D9'>
                                <Heading as='h2' title='02' color='Red' lineHeight='1'></Heading>
                                <Heading as='h3' title='ICONIC7 – 7 studio credits for €140' variant='small' margin='0px 0px 30px'></Heading>
                                <Text as='p' title='These credits can be used to book into our RIDE or SWEAT classes at both Perpetua locations!'></Text>
                            </Stack>
                            <Stack p='15px 0px'  borderBottom='1px solid #D9D9D9'>
                                <Heading as='h2' title='03' color='Red' lineHeight='1'></Heading>
                                <Heading as='h3' title='JustGYM Membership – €69 per month' variant='small' margin='0px 0px 30px'></Heading>
                                <Text as='p' title='Enjoy unlimited access to our JustGYM space at Windmill Lane as well as a complimentary consultation with our head trainer and nutritionist'></Text>
                            </Stack>
                            <Stack p='15px 0px 0px'>
                                <Heading as='h2' title='04' color='Red' lineHeight='1'></Heading>
                                <Heading as='h3' title='To avail of any of these offers, simply show this offer to our Front of House team in Windmill Lane or Lennox Street!' variant='small' margin='0px 0px 30px'></Heading>
                            </Stack>
                        </VStack>
                    </Box>
                    </Flex>
                </Container>
            </Style.IconicOfferWraper>
                
            <Style.StudioClassesWraper>
                <Container maxW={{ md:'container.lg',xl:'container.xl'}}  >
                    <Style.StudioClasses>
                        <Style.StudioClassesBox backgroundImage='url(./images/ionic-bg.jpg)' ></Style.StudioClassesBox>
                        <Style.StudioClassesBox>
                            <Heading as='h2' title='STUDIO CLASSES' margin={{md:'0px 0px 10px',lg:'0px 0px 30px'}} color='white'></Heading>
                            <Text as='p' color='white' title='Get the studio experience with private classes exclusive for the Iconic community. All our classes are suitable for ALL fitness levels! We provide sweat towels, shower towels, and have fully equipped changing/shower facilities at both of our locations.'></Text>
                        </Style.StudioClassesBox>
                    </Style.StudioClasses>
                </Container>
            </Style.StudioClassesWraper>

            <Style.BookInWrapper>
                <Container maxW={{ md:'container.lg',xl:'container.xl'}}>
                    <Style.BookIn>
                        <Heading as='h2' title='HOW TO BOOK IN:'variant='medium' margin='0px 0px 15px'></Heading>
                        <Heading as='h4' title={<Link href="/sign-up">Create a Perpetua account by clicking here!</Link>} margin='0px 0px 0px' variant='smallmid' color='Red'></Heading>
                    </Style.BookIn>
                </Container>
            </Style.BookInWrapper>

            <Style.AllClassesWrapper>
                <Stack>
                    <Style.AllClassesBody>
                        <Style.AllClassesBox backgroundImage='url(./images/tread-banner.jpg)'height={{sm:'250px',md:'350px'}} ></Style.AllClassesBox>
                        <Style.AllClassesBox backgroundColor='#eceded'>
                            <Heading as='h2' title='TREAD & SHRED' margin='0px auto 30px 0px' ></Heading>
                            <Text as='p'title='Our Tread & Shred class is a 45-minute session where you’ll alternate between running blocks on the treadmill and strength blocks on the floor.' margin='0px 0px 15px'></Text>
                            <Text as='p'margin='0px 0px 15px' title='Don’t worry if you’re not a runner – our coaches will give you a range of speeds and it’s really just all about what feels good to you! The floor work offers a variety of strength moves using weights, bodyweight, and benches. Again, don’t worry if you don’t have a lot of fitness experience – we have plenty of weights ranging from light to heavy, and your coach is here to help you with each movement.'></Text>
                            <Text as='p'title='If something doesn’t feel right, we got you! Scale back, slow down, ask for a modification – you do you. We guarantee that by the end of the class you’ll be glad you came. It’s an amazing workout, with a great playlist, led by a world-class coach.'></Text>
                        </Style.AllClassesBox>
                    </Style.AllClassesBody>
                </Stack>
                <Stack>
                    <Style.AllClassesBody>
                        <Style.AllClassesBox backgroundColor='white' order={{sm:'2',md:'2',lg:'1'}}>
                            <Heading as='h2' title='RIDE45' margin='0px auto 30px 0px' ></Heading>
                            <Text as='p' margin='0px auto 30px 0px' title='RIDE45 is a 45-minute indoor cycling class that is old-school meets new-school. You’ll get your classic spin class experience, with heavy climbs and endurance work – but our classes offer that extra something special. From the lights, to the technology, to the music, to the bike itself, RIDE45 is so much more than your average spin session.'></Text>
                            <Text as='p' margin='0px auto 30px 0px' title='RIDE45 is metrics-focused so you’ll see your stats on the screen, but if you don’t want to be on display, simply ask your coach to remove your bike from the screen, no problem! Push your limits and be proud of your effort in the Ride room!'></Text>
                        </Style.AllClassesBox>
                        <Style.AllClassesBox backgroundImage='url(./images/Ride-banner.jpg)' order={{sm:'1',md:'1',lg:'2'}} height={{sm:'250px',md:'350px'}}></Style.AllClassesBox>
                    </Style.AllClassesBody>
                </Stack>
                <Stack>
                    <Style.AllClassesBody>
                        <Style.AllClassesBox backgroundImage='url(./images/corporate5.jpg)' height={{sm:'250px',md:'350px'}}></Style.AllClassesBox>
                        <Style.AllClassesBox backgroundColor='#eceded'>
                            <Heading as='h2' title='RHYTHM RIDE' margin='0px auto 30px 0px' ></Heading>
                            <Text as='p' margin='0px auto 30px 0px' title='Vibes are immaculate in our Rhythm Ride classes. In this class we turn off the screens and ride entirely to the beat of the music. Our Rhythm instructors spend countless hours creating the perfect playlist that will have you vibing and dancing through your 45-minute class. Throughout the class your instructor will guide you through some fun choreography moves, push you through heavy climbs, and get your legs moving through some double-time sprints.'></Text>
                            <Text as='p' title='Don’t worry if you don’t have experience with this type of spin class (or any spin class, really!) – your instructor will offer plenty of modifications and it’s really just all about having fun! You do you, feel the music, and enjoy yourself. This is a PARTY on a bike.'></Text>
                        </Style.AllClassesBox>
                    </Style.AllClassesBody>
                </Stack>
                <Stack>
                    <Style.AllClassesBody>
                        <Style.AllClassesBox backgroundColor='white' order={{sm:'2',md:'2',lg:'1'}} height={{md:'auto'}}>
                            <Heading as='h2' title='RUMBLE' margin='0px auto 5px 0px' ></Heading>
                            <Heading as='h4' variant='extrasmall' title='GET READY TO RUMBLE.' margin='0px auto 15px 0px' ></Heading>
                            <Text as='p' margin='0px auto 30px 0px' title='We have ripped up the boxing rulebook to bring you a fast-paced workout that improves your strength, endurance and power while working up a sweat to some big tunes.'></Text>
                            <Text as='p' margin='0px auto 30px 0px' title='Join us for 45 minutes of strength, endurance, power moves and treadmill rounds that will leave your body burning but your face smiling.'></Text>
                            <Stack>
                                <Box>
                                    <Heading as='h2' variant='small' title='TREADMILL' margin='0px auto 0px 0px' color='Red'></Heading>
                                    <Text as='p'title='Lace up your trainers and get ready to work! You will be guided through running intervals by your coach on our state-of-the-art treadmills. Not a runner? Don’t worry! Your coach will provide you with a range of beginner, intermediate and advanced speeds. These treadmill rounds to compliment the grit you need on the bag. '></Text>
                                </Box>
                            </Stack>
                            <Stack marginTop='30px'>
                                <Box>
                                    <Heading as='h2' variant='small' title='ON THE BAG' margin='0px auto 0px 0px' color='Red'></Heading>
                                    <Text as='p'title='Get ready to learn the groundwork of boxing from your stance and form to killer punching combos, combined with strength and conditioning bodyweight drills specific to boxing. Expect skills, drills and combinations, along with some powerful bodyweight moves. '></Text>
                                </Box>
                            </Stack>
                        </Style.AllClassesBox>
                        <Style.AllClassesBox backgroundImage='url(./images/ionic-bg.jpg)' height={{sm:'250px',md:'350px',lg:'700px'}} order={{sm:'1',md:'1',lg:'2'}}></Style.AllClassesBox>
                    </Style.AllClassesBody>
                </Stack>
                <Stack>
                    <Style.AllClassesBody>
                        <Style.AllClassesBox backgroundImage='url(./images/ionic-bg.jpg)' height={{sm:'250px',md:'350px'}}></Style.AllClassesBox>
                        <Style.AllClassesBox backgroundColor='#eceded'>
                            <Heading as='h2' title='JUSTGYM' margin='0px auto 30px 0px' ></Heading>
                            <Text as='p' margin='0px auto 30px 0px' title='JustGYM at Perpetua has all you need, from cardio to heavy lifting, and everything in between. Our open-plan training space is big and bold. Find your space and get inspired.'></Text>
                            <Text as='p' margin='0px auto 30px 0px' title='As a JustGYM member at Perpetua, you can book into to the training space anytime we are open. All JustGYM members receive a complimentary consultation with a trainer who can help put together a program to suit your goals and needs!'></Text>
                            <Text as='p'  title='Our JustGYM training area is separated into five distinct zones: cardio conditioning, functional training, free weights, and fixed weights. Each section features the very latest model equipment from Keiser, Concept 2, Skill Mill, TechnoGym, Step and Versaclimber to name a few.'></Text>
                        </Style.AllClassesBox>
                    </Style.AllClassesBody>
                </Stack>
                <Stack>
                    <Style.buttonGroup>
                        <Link href='/sign-up' _hover={{textDecoration:'none', color:'white'}}><Button title='Get StartedCREATE A PERPETUA ACCOUNT' color='black' margin='40px 0px 0px '/> </Link>
                        <Link href='/contact-us' _hover={{textDecoration:'none', color:'white'}}><Button title='Contact' color='black' margin='40px 0px 0px '/> </Link>
                        <Link href='/studios' _hover={{textDecoration:'none', color:'white'}}><Button title='LEARN MORE ABOUT STUDIO CLASSES' color='black' margin='40px 0px 0px '/> </Link>
                    </Style.buttonGroup>
                </Stack>
            </Style.AllClassesWrapper>

            <Style.AnyWhereWrapper>
                <Heading as='h2' title='PERPETUA ANYWHERE' margin='0px auto 30px' ></Heading>
                <Container maxW={{ md:'container.lg',xl:'container.xl'}}>
                    <Style.AnyWherebody>
                        <Text as='p' margin='0px auto 30px' title='Perpetua Anywhere is available on all devices, encompassing all your health, fitness and wellness needs from training at home or in the gym to customising your own nutrition. We have designed this platform for the hybrid world, catering for everyone from beginner to advanced level. As part of the ICONIC Community, you get 25% OFF a yearly subscription to Perpetua Anywhere – so download the app and enjoy!'></Text>
                        <Heading as='h2' title='HOW TO GET STARTED:' variant='smallmid' margin='0px auto 30px' ></Heading>
                        <Text as='p' margin='0px auto 10px' title='Download the app & create an account.'></Text>
                        <Text as='p' margin='0px auto 10px' title='Enter code: PANY25OFF at checkout when you select yearly subscription'></Text>
                        <Text as='p' margin='0px auto 10px' title='Enjoy a wide range of classes that you can do at home, on the road, or in the gym at your ICONIC location!'></Text>
                        <Link href='https://apps.apple.com/ie/app/perpetua-fitness/id1444090131' _hover={{textDecoration:'none', color:'white'}} target="_blank"><Button title='Download APP' color='black' margin='40px 0px 0px '/> </Link>
                    </Style.AnyWherebody>
                </Container>
            </Style.AnyWhereWrapper>

        </Style.IconicWraper>
        </>
    )
};
  
 export default Iconic ;