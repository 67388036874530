import * as React from 'react';
import { Box, Flex, Image, Link} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';
//import{Link} from 'react-router-dom';
//import LinkButton from '../Button/LinkButton';
import Button from '../Button/CustomeButton';



function PriceCard(props) { 
    return (
        <Box pos='relative' border={props.border}> 
       {props.cardTitle && <Heading lineHeight='0.9' title={props.cardTitle} variant='small' margin='0px 0px 30px' textAlign="center" />}

       <Flex textAlign={{sm:'center',md:'left'}} justifyContent={{sm:'center',md:'flex-start;'}} alignItems='center' minH='242px' width='100%' padding={{sm:'20px 10px ',md:'21px'}} backgroundColor='#F4F4F3' flexDirection={{sm:'column',md:'row'}}>
        {props.tagTitle && <Heading className='tag' variant='smallheading' as='small' lineHeight='0.9' title={props.tagTitle} ></Heading>}

            <Box width={{sm:"100%", md:"auto"}} padding={{sm:'0px',md:'23px 15px'}} marginBottom={{sm:'30px',md:'0'}}>
                <Heading title={props.offer} variant='smallheading' lineHeight='0.9' />
                <Heading  title={props.currency} variant='medium' lineHeight='0.9' />
            </Box>
            <Box width={{sm:"100%", md:"auto"}} paddingLeft={{sm:"0px", md:"20px"}}>
                <Heading fontSize={{sm:"20px"}} lineHeight='0.9' title={props.planname} variant='smallmid' margin='0px 0px 10px'></Heading>
                <Text fontSize={{sm:"14px", md:"16px"}} color="#7D7D7D" as='p' title={props.description} colors='Grey' width={props.width} margin="0 0 5px"></Text>
                <Link href={props.link} _hover="textDecoration:none" isExternal={props.targetBlank} >
                    <Button title={props.buttonTitle} color='#000' className="wow fadeInUp" margin='15px 0px 0px'/>

                </Link>
            </Box>
        </Flex>
      </Box>
      );
    }
export default PriceCard;












