import { Flex,Box} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const Wrapper = styled(Flex)(props => ({
    alignItems:'center',
    flexDirection:'row',
    gap:30,
    "@media screen and (max-width:767px)": {
        flexDirection:'column',
    },

}))

export const LeftBox = styled(Box)(props => ({
    width:'43%',
    "@media screen and (max-width:991px)": {
        width:'35%',
    },
    "@media screen and (max-width:767px)": {
        width:'100%',
    },
}))
export const RightBox = styled(Flex)(props => ({
    width:'55%',
    alignItems:'center',
    flexDirection:'row',
    gap:20,
    "@media screen and (max-width:991px)": {
        width:'63%',
    },
    "@media screen and (max-width:767px)": {
        width:'100%',
    },
    "@media screen and (max-width:480px)": {
        flexDirection:'column',
    },
}))
export const VideoWrapper = styled(Box)(props => ({
    width:'49%',
    "@media screen and (max-width:480px)": {
        width:'100%',
        marginBottom:'30px'
    },
}))

export const TextWrapper = styled(Box)(props => ({
    width:'100%',
    textAlign:'center',
    paddingTop:'30px'
}))