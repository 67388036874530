import { Box, Flex, Link} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import { device } from "../../Device.types";


export const PageWrapper= styled(Flex)(props => ({
    flexDirection: 'column',
}))
export const ContentCard1= styled(Box)(props => ({
    paddingBottom:50,
    paddingTop:50,
    textAlign:'center',
}))

export const CnmButton= styled(Link)(props => ({
    flexDirection: 'column',
    fontSize:'25px',
    color:'#000',
    background:'transparent',
    border:'2px solid #d12c41',
    borderRadius:100,
    padding:'12px 25px',
    textTransform:'uppercase',
    marginTop:'30px',
    display:'inline-flex',
    alignItems:'center',
    justifyContent:'center',
    "&:hover": {
        background:'#d12c41',
        color:'#fff'
      }
}))

export const ContentCardBlack= styled(Flex)(props => ({
    paddingBottom:50,
    paddingTop:50,
    alignItems:'center',
    justifyContent:'center',
    background:'#000',
}))

export const ContentCardWhite= styled(Flex)(props => ({
    paddingBottom:50,
    paddingTop:50,
    alignItems:'center',
    justifyContent:'center',
    background:'#fff',
}))

export const ContentCardBlackWrapper= styled(Flex)(props => ({
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'row',
    gap:'50px',
    
    "@media screen and (max-width:640px)": {
    flexDirection:'column',
    gap:'25px'
    },

}))

export const ContentHeading= styled(Flex)(props => ({
    flexDirection:'column',
    width:'40%',
    textAlign:'right',

    "@media screen and (max-width:640px)": {
        textAlign:'center',
        width:'100%'
        },

}))

export const ContentDescription= styled(Flex)(props => ({
    flexDirection:'column',
    width:'60%',
    textAlign:'Left',
    "@media screen and (max-width:640px)": {
        textAlign:'center',
        width:'100%'
        },
}))
export const ContentDescriptionWrpper= styled(Flex)(props => ({
    flexDirection:'row',
    alignItems:'flex-start',
    gap:'15px',
    paddingTop:'15px',
}))

export const tremsandcondition= styled(Box)(props => ({
    paddingBottom:50,
    paddingTop:50,
    textAlign:'center',
}))

export const tremsandconditionWrapper= styled(Box)(props => ({
    
}))